import "./ServicePage.css";
import Cover from "../../components/Cover/Cover";
import ServiceAbout from "./ServiceAbout/ServiceAbout";
import ServicePrices from "./ServicePrices/ServicePrices";
import { Helmet } from "react-helmet-async";
import SeoText from "./SeoText/SeoText";

const ServicePage = ({
  coverDescription,
  servicePhotos,
  serviceTitle,
  serviceTexts,
  pricesObj,
  metaTitle,
  metaDescription,
  metaUrl,
  seoText,
  scrollTo,
}) => {
  return (
    <>
      <Helmet>
        <title>{metaTitle}</title>
        <meta name="description" content={metaDescription} />
        <meta property="og:url" content={metaUrl} />
        <link rel="canonical" href={metaUrl} />
        <script>{`
        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','GTM-K92BJD9R')
      `}</script>
      </Helmet>
      <div className="service__page">
        <Cover coverDescription={coverDescription} />
        <ServiceAbout
          servicePhotos={servicePhotos}
          serviceTexts={serviceTexts}
          serviceTitle={serviceTitle}
          scrollTo={scrollTo}
        />
        <ServicePrices
          services={pricesObj.services}
          isComplicated={pricesObj.isComplicated}
        />
        {seoText && <SeoText text={seoText} />}
      </div>
      {/* Код Google Tag Manager (noscript) */}
      <noscript>
        <iframe
          src="https://www.googletagmanager.com/ns.html?id=GTM-K92BJD9R"
          height="0"
          width="0"
          style="display:none;visibility:hidden"
        ></iframe>
      </noscript>
    </>
  );
};

export default ServicePage;
