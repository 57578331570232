export const allServices = [
  {
    categoryTitle: "Косметологія",
    isComplicated: true,
    subcategorys: [
      {
        subcategoryTile: "Діагностика",
        services: [
          {
            title: "Консультація косметолога",
            price: "550 грн",
          },
        ],
      },
      {
        subcategoryTile: "Ін’єкційна косметологія (ботулінотерапія)",
        services: [
          {
            title: "Ботулінотерапія одна зона",
            price: "2000 грн",
          },
          {
            title: "Ботулінотерапія Лоб+міжбрів’я",
            price: "3850 грн",
          },
          {
            title: "Ботулінотерапія Лоб+міжбрів’я+очі",
            price: "5500 грн",
          },
          {
            title: "Ботулінотерапія Full face",
            price: "7700 грн",
          },
          {
            title: "Ботулінотерапія  Платізма (шия)",
            price: "6600 грн",
          },
          {
            title: "Лікування гіпергідрозу (Диспорт)",
            price: "5500 грн",
          },
        ],
      },
      {
        subcategoryTile: "Ін’єкційна косметологія (бланшинг)",
        services: [
          {
            title: "Бланшинг поверхневих зморшок (Belotero Balance)",
            price: "6600 грн",
          },
          {
            title: "Бланшинг поверхневих зморшок (Belotero Soft)",
            price: "6600 грн",
          },
        ],
      },
      {
        subcategoryTile: "Ін’єкційна косметологія (контурна пластика)",
        services: [
          {
            title: "Зона губ Belotero 0,6 ml",
            price: "4500 грн",
          },
          {
            title: "Зона губ Belotero 1 ml",
            price: "5500 грн",
          },
          {
            title: "Зона губ Pluryal 1 ml",
            price: "5000 грн",
          },
          {
            title: "Зона губ Saypha 1ml",
            price: "4000 грн",
          },
          {
            title: "Зона вилиць/підборіддя та кути нижної щелепи Belotero 1 ml",
            price: "6600 грн",
          },
          {
            title: "Зона вилиць/підборіддя та кути нижної щелепи Saypha 1 ml",
            price: "4500 грн",
          },
          {
            title: "Зона вилиць/підборіддя та кути нижної щелепи Pluryal 1 ml",
            price: "5500 грн",
          },
          {
            title: "Зона губ Juvederm 0.6 мл",
            price: "4800 грн",
          },
          {
            title: "Зона губ Juvederm 1.0 мл",
            price: "6500 грн",
          },
          {
            title: "Зона вилиць Juvederm 1.0 мл",
            price: "7100 грн",
          },
          {
            title: "Зона підборіддя/нижня щелепа борозди Juvederm 1.0 мл",
            price: "7000 грн",
          },
          {
            title: "Зона підборіддя/нижня щелепа борозди Juvederm 0.6 мл",
            price: "6500 грн",
          },
          {
            title: "Розчинення філера препаратом PB-serum",
            price: "1550 грн",
          },
        ],
      },
      {
        subcategoryTile:
          "Ін’єкційна косметологія (Біоревіталізація/Біорепарація)",
        services: [
          {
            title: "Біоревіталізація/Біорепарація обличчя Saypha Rich 1 ml",
            price: "3300 грн",
          },
          {
            title: "Біоревіталізація/Біорепарація обличчя Belotero Revive 1 ml",
            price: "4400 грн",
          },
          {
            title: "Біоревіталізація/Біорепарація обличчя Rejuran HB 1 ml",
            price: "3850 грн",
          },
          {
            title: "Біоревіталізація/Біорепарація обличчя HYALUAL Electri 1.5 ml",
            price: "3100 грн",
          },
          {
            title: "Біоревіталізація/Біорепарація обличчя HYALUAL Smart 1.8% 1ml",
            price: "3300 грн",
          },
          {
            title: "Біоревіталізація/Біорепарація обличчя HYALUAL booster 2.5 % 1 ml",
            price: "3500 грн",
          },
          {
            title: "Біоревіталізація/Біорепарація (обличча+шия +декольте/руки) TwAc 2.0 3ml",
            price: "5200 грн",
          },
          {
            title: "Ін'єккційна косметологія (Біореві+26:46талізація/Біорепарація)",
            price: "5500 грн",
          },
          {
            title: "Біоревіталізація/Біорепарація (обличча+шия +декольте/руки) Plinest (Mastelli) 2 ml",
            price: "4600 грн",
          },
          {
            title: "Біоревіталізація/Біорепарація (обличча+шия +декольте/руки) HP CELL Vitaran і II 2 ml",
            price: "3500 грн",
          },
          {
            title: "Біоревіталізація/Біорепарація (обличча+шия +декольте/руки) HP CELL Vitaran Whitening &Anti-aging 2 мл",
            price: "4300 грн",
          },
          {
            title: "Біоревіталізація/Біорепарація (обличча+шия +декольте/руки) Neauvia Hidro Delux 2.5 ml",
            price: "3300 грн",
          },
          {
            title: "Біоревіталізація/Біорепарація (обличча+шия +декольте/руки) Newest (Mastelli) 2 ml",
            price: "4900 грн",
          },
          {
            title: "Біоревіталізація/Біорепарація (обличча+шия +декольте/руки) HYALUAL Smart 1.8% 2 ml",
            price: "3900 грн",
          },
          {
            title: "Біоревіталізація/Біорепарація (обличча+шия +декольте/руки) HYALUAL booster 2.5% 2 ml",
            price: "4200 грн",
          },
          {
            title: "Біоревіталізація/Біорепарація періорбітальної зони Plinest eye 2 ml",
            price: "4500 грн",
          },
          {
            title: "Біоревіталізація/Біорепарація періорбітальної зони Rejuran I 1 ml",
            price: "3300 грн",
          },
          {
            title: "Біоревіталізація/Біорепарація періорбітальної зони TwAc eyes1 ml",
            price: "3100 грн",
          },
          {
            title: "Біоревіталізація/Біорепарація періорбітальної зони HP CELL Vitaran і 1 ml",
            price: "2600 грн",
          },
          {
            title: "Біорепарація+Нанохілінг(обличча+шия+декольте/руки/тіло) Hyalual Perfoskin",
            price: "3500 грн",
          },
          {
            title: "RRS long lastic 3 ml",
            price: "4500 грн",
          },
          {
            title: "Біоревіталізація/Біорепарація(обличча+шия+декольте/руки) RRS HYALIFT 75 proactive 5 ml",
            price: "2800 грн",
          },
        ],
      },
      {
        subcategoryTile: "Мезотерапія",
        services: [
          {
            title: "Мезотерапія періорбітальної зони RRS HA Eyes",
            price: "1200 грн",
          },
          {
            title: "Мезотерапія періорбітальної зони Dermaheal Dark Circle",
            price: "1900 грн",
          },
          {
            title:
              "Мезотерапія волосистої частини голови XL HAIR",
            price: "1600 грн",
          },
          {
            title: "Мезотерапія волосистої частини голови DR. CYJ",
            price: "3600 грн",
          },
          {
            title: "Мезотерапія волосистої частини голови Dermaheal HL",
            price: "1100 грн",
          },
        ],
      },
      {
        subcategoryTile: "Ліполітична терапія",
        services: [
          {
            title: "Ліполітична терапія PBserum",
            price: "3000 грн",
          },
          {
            title: "Ліполітична терапія RRS HA CELLUTRIX",
            price: "2000 грн",
          },
        ],
      },
      {
        subcategoryTile: "Доглядова косметологія",
        services: [
          {
            title: "Комбінована чистка обличчя",
            price: "950 грн",
          },
          {
            title: "Комбінована чистка обличчя + пілінги",
            price: "1100 грн",
          },
          {
            title: "Карбоксітерапія",
            price: "800 грн",
          },
          {
            title: "Експрес догляд(по типу шкіри)",
            price: "800 грн",
          },
          {
            title: "Масаж обличчя, шиї та декольте",
            price: "700 грн",
          },
          {
            title: "Вогонь та лід IS Clinical",
            price: "1400 грн",
          },
          {
            title: "Платиновий антивіковий догляд  MEDIK 8",
            price: "1100 грн",
          },
          {
            title: "Механічна чистка спини+пілінг",
            price: "1400 грн",
          },
          {
            title: "Пілінг мигдалевий",
            price: "900 грн",
          },
          {
            title: "Пілінг азелаїновий",
            price: "900 грн",
          },
          {
            title: "Пілінг освітлюючий",
            price: "900 грн",
          },
          {
            title: "Ретиноловий пілінг периорбітальної зони",
            price: "900 грн",
          },
          {
            title:
              "Поверхнево-серединний пілінг з біоревіталізуючим ефектом  Appex ",
            price: "1700 грн",
          },
          {
            title:
              "Поверхнево-серединний пілінг з біоревіталізуючим ефектом  PRX33 ",
            price: "1800 грн",
          },
        ],
      },
    ],
  },
  {
    categoryTitle: "Дерматологія",
    isComplicated: true,
    subcategorys: [
      {
        subcategoryTile: "Діагностика",
        services: [
          {
            title: "Консультація лікаря-дерматолога",
            price: "600 грн",
          },
          {
            title: "Повторна консультація лікаря-дерматолога",
            price: "450 грн",
          },
        ],
      },
      {
        subcategoryTile: "Апаратні методики",
        services: [
          {
            title: "Видалення судин діодним лазером (крила носа)",
            price: "1000 грн",
          },
          {
            title: "Видалення судин діодним лазером (ніс повністю)",
            price: "2000 грн",
          },
          {
            title: "Видалення судин діодним лазером (щоки)",
            price: "2600 грн",
          },
          {
            title:
              "Видалення судин діодним лазером (ноги, по тілу ділянка 10х10 см)",
            price: "2700 грн",
          },
          {
            title: "Видалення судин діодним лазером (підборіддя)",
            price: "1200 грн",
          },
          {
            title:
              "Видалення судин діодним лазером (ділянка над верхньою губою)",
            price: "1000 грн",
          },
          {
            title: "Видалення гемангіоми діодним лазером до 3 мм",
            price: "300 грн",
          },
          {
            title:
              "Видалення гемангіом діодним лазером від 10 шт по тілу (за шт)",
            price: "200 грн",
          },
          {
            title:
              "Видалення гемангіом діодним лазером від 10 шт (обличчя, статеві органи, за шт)",
            price: "250 грн",
          },
          {
            title:
              "Видалення гемангіом діодним лазером від 20 шт по тілу (за шт)",
            price: "150 грн",
          },
          {
            title:
              "Видалення гемангіом діодним лазером від 20 шт (обличчя, статеві органи, за шт)",
            price: "200 грн",
          },
          {
            title: "Одиночний імпульс",
            price: "8 грн",
          },
          {
            title: "Гідропілінг",
            price: "900 грн",
          },
          {
            title: "RF-ліфтинг неінвазивний",
            price: "900 грн",
          },
          {
            title: "Киснева мезотерапія",
            price: "900 грн",
          },
          {
            title: "Голівудський догляд",
            price: "1300 грн",
          },
        ],
      },
      {
        subcategoryTile: "Лікування рубців полінуклеотидами",
        services: [
          {
            title: "Rejuran S (лікування рубців/стрій) 1 ml",
            price: "3300 грн",
          },
          {
            title:
              "Plinest one(лікування стріїв,рубців та ліфтинг шкіри тіла) ",
            price: "1650 грн",
          },
          {
            title:
              "Лікування рубців ферментами Pbserum  HA 1.5 HIGH",
            price: "8500 грн",
          },
          {
            title:
              "Лікування рубців ферментами Pbserum",
            price: "2500 грн",
          },
        ],
      },
      {
        subcategoryTile: "PRP-терапія",
        services: [
          {
            title: "PRP-терапія обличчя (2 пробірки)",
            price: "2200 грн",
          },
          {
            title: "PRP-терапія волосистої частини голови (2 пробірки)",
            price: "2200 грн",
          },
        ],
      },
    ],
  },
  // {
  //   categoryTitle: "УЗ ДІАГНОСТИКА",
  //   isComplicated: false,
  //   subcategorys: [
  //     {
  //       services: [
  //           {
  //               title: "УЗД нирок",
  //               price: "300 грн",
  //             },
  //             {
  //               title: "УЗД сечового міхура",
  //               price: "300 грн",
  //             },
  //             {
  //               title: "УЗД нирок та сечового міхура",
  //               price: "400 грн",
  //             },
  //             {
  //               title: "УЗД передміхурової залози",
  //               price: "300 грн",
  //             },
  //             {
  //               title: "УЗД нирок, сечового міхура та передміхурової залози",
  //               price: "600 грн",
  //             },
  //             {
  //               title: "УЗД щитоподібної залози",
  //               price: "300 грн",
  //             },
  //             {
  //               title: "УЗД органів малого тазу (трансабдомінально)",
  //               price: "400 грн",
  //             },
  //             {
  //               title: "УЗД органів малого тазу (трансвагінально)",
  //               price: "450 грн",
  //             },
  //             {
  //               title: "УЗД серця ",
  //               price: "600 грн",
  //             },
  //             {
  //               title: "УЗД серця черезстравохідне",
  //               price: "1200 грн",
  //             },
  //             {
  //               title: "УЗД серця черезстравохідне з медикаментозним сном",
  //               price: "1700 грн",
  //             },
  //             {
  //               title: "УЗД молочних залоз",
  //               price: "450 грн",
  //             },
  //             {
  //               title: "Дуплексне (триплексне) сканування судин голови та шиї",
  //               price: "600 грн",
  //             },
  //             {
  //               title: "Дуплексне (триплексне) сканування артерій нижніх кінцівок",
  //               price: "500 грн",
  //             },
  //             {
  //               title: "Дуплексне (триплексне) сканування артерій верхніх кінцівок",
  //               price: "500 грн",
  //             },
  //             {
  //               title:
  //                 "Дуплексне (триплексне) сканування артерій та вен нижніх кінцівок",
  //               price: "700 грн",
  //             },
  //             {
  //               title:
  //                 "Дуплексне (триплексне) сканування артерій та вен верхніх кінцівок",
  //               price: "700 грн",
  //             },
  //             {
  //               title: "Дуплексне (триплексне) сканування вен нижніх кінцівок",
  //               price: "500 грн",
  //             },
  //             {
  //               title: "Дуплексне (триплексне) сканування вен верхніх кінцівок",
  //               price: "500 грн",
  //             },
  //       ],
  //     },
  //   ],
  // },
  {
    categoryTitle: "ПРОКТОЛОГІЯ",
    isComplicated: false,
    subcategorys: [
      {
        services: [
          {
            title: "Консультація проктолога",
            price: "600 грн",
          },
          {
            title: "Повторна консультація проктолога (протягом 2 тижнів)",
            price: "400 грн",
          },
          {
            title: "Аноскопія",
            price: "700 грн",
          },
          {
            title: "Ректороманоскопія",
            price: "1200 грн",
          },
          {
            title:
              "Видалення зовнішнього гемороїдального вузла І категорії складності",
            price: "4500 грн",
          },
          {
            title:
              "Видалення зовнішнього гемороїдального вузла IІ категорії складності",
            price: "5300 грн",
          },
          {
            title:
              "Видалення зовнішнього гемороїдального вузла IІІ категорії складності",
            price: "6300 грн",
          },
          {
            title:
              "Видалення доброякісних новоутворень підвищеної складності (анальна бахромка)",
            price: "5700 грн",
          },
          {
            title: "Видалення анального поліпу I категорії складності",
            price: "3100 грн",
          },
          {
            title: "Видалення анального поліпу IІ категорії складності",
            price: "4200 грн",
          },
          {
            title: "Видалення анального поліпу IІІ категорії складності",
            price: "5300 грн",
          },
          {
            title:
              "Видалення тромбу гемороїдального вузла I категорії складності",
            price: "3800 грн",
          },
          {
            title:
              "Видалення тромбу гемороїдального вузла IІ категорії складності",
            price: "4500 грн",
          },
          {
            title:
              "Видалення тромбу гемороїдального вузла IІІ категорії складності",
            price: "5300 грн",
          },
          {
            title: "Коагуляція гіпертрофованої анальної залози (папілліт)",
            price: "3000 грн",
          },
          {
            title: "Видалення стороннього тіла з анального каналу",
            price: "5200 грн",
          },
          {
            title: "Оперативне лікування анальної тріщини",
            price: "9000 грн",
          },
          {
            title: "Оперативне лікування складних анальних тріщин (двох)",
            price: "14000 грн",
          },
          {
            title:
              "Оперативне лікування складних анальних тріщин зі спазмом сфінктера",
            price: "15500 грн",
          },
          {
            title: "Оперативне лікування внутрішнього геморою радіохвильовим методом",
            price: "14000 грн",
          },
          {
            title: "Оперативне лікування внутрішнього геморою з використанням методики HAL-RAR",
            price: "32000 грн",
          },
          {
            title:
              "Медикаментозний супровід при оперативному лікуванні (наркоз)",
            price: "2000 грн",
          },
          {
            title: "Кожна наступна година наркозу",
            price: "1000 грн",
          },
        ],
      },
    ],
  },
  {
    categoryTitle: "ЛОР",
    isComplicated: false,
    subcategorys: [
      {
        services: [
          {
            title: "Консультація ЛОРа",
            price: "600 грн",
          },
          {
            title:
              "Консультація оториноларинголога з ендоскопічним обстеженням",
            price: "900 грн",
          },
          {
            title: "Повторна консультація ЛОРа (протягом 1 місяця)",
            price: "450 грн",
          },
          {
            title: "Промивання лакун мигдаликів",
            price: "450 грн",
          },
          {
            title: "Продування слухових труб по Політцеру",
            price: "145 грн",
          },
          {
            title: "Вливання в гортань лікарських засобів",
            price: "290 грн",
          },
          {
            title: "Видалення сірчаної пробки 1 вухо",
            price: "350 грн",
          },
          {
            title: "Туалет носа",
            price: "350 грн",
          },
          {
            title: "Туалет вуха",
            price: "350 грн",
          },
          {
            title:
              "Катеризація слухової труби з введенням лікарських засобів (з однієї сторони)",
            price: "400 грн",
          },
          {
            title: "Тимпанопункція",
            price: "650 грн",
          },
          {
            title: "Пункція верхньощелепної пазухи",
            price: "800 грн",
          },
          {
            title: "ПХО рани( якщо буде запит)",
            price: "від 800 грн",
          },
          {
            title:
              "Хемокаустика (припікання) судин носа, що кровоточать (трихлороцтовою кислотою)",
            price: "500 грн",
          },
          {
            title: "Діатермокоагуляція судин носа",
            price: "700 грн",
          },
          {
            title: "Передня тампонада носа тампоном «мероцель», 1 тампон",
            price: "400 грн",
          },
          {
            title: "Передня тампонада носа марлевим тампоном",
            price: "300 грн",
          },
          {
            title: "Видалення тампонів з носа",
            price: "300 грн",
          },
          {
            title: "Видалення чужорідного тіла з порожнини носа, глотки, вуха",
            price: "500 грн",
          },
          {
            title: "Видалення доброякісних новоутворень ЛОР-органів",
            price: "від 1200 грн",
          },
          {
            title: "Розтин паратонзилярного або парафаренгіального абсцесу",
            price: "1200 грн",
          },
          {
            title: "Ревізія абсцесу",
            price: "500 грн",
          },
          {
            title:
              "Розтин фурункулів зовнішнього носа або зовнішнього вуха, розтин кісти піднебінного мидалика І кат.",
            price: "500 грн",
          },
          {
            title:
              "Розтин фурункулів зовнішнього носа або зовнішнього вуха, розтин кісти піднебінного мигдалика ІІ кат.",
            price: "800 грн",
          },
          {
            title: "Ревізія фурункула",
            price: "300 грн",
          },
          {
            title: "Міринготомія/парацентез",
            price: "1000 грн",
          },
          {
            title: "Взяття біопсії з носа, без вартості ПГД",
            price: "від 650 грн",
          },
          {
            title: "Взяття біопсії з глотки, без вартості ПГД",
            price: "650 грн",
          },
          {
            title: "Розкриття гематоми вуха, носу",
            price: "700 грн",
          },
          {
            title: "Шунтування барабанної перетинки (з однієї сторони)",
            price: "7000 грн",
          },
          {
            title:
              "Підслизова вазотомія нижніх носових раковин",
            price: "8000 грн",
          },
          {
            title:
              "Ендоскопічна вазотомія з ощадливою конхотомією задніх кінців нижніх носових раковин",
            price: "12000 грн",
          },
          {
            title: "Септопластика",
            price: "12000 грн",
          },
          {
            title: "Ендоскопічна септопластика",
            price: "18000 грн",
          },
          {
            title: "Ендоскопічна гайморотомія однобічна ( з видаленням кісти)",
            price: "15000 грн",
          },
          {
            title: "Ендоскопічна гайморотомія однобічна ( з видаленням міцетоми/стороннього тіла)",
            price: "18000 грн",
          },
          {
            title: "Ендоскопічна шейверна поліпетмоїдотомія",
            price: "Від 12000 до 20000 грн",
          },
          {
            title: "Поліпотомія у дорослого класичним методом",
            price: "8000 грн",
          },
          {
            title: "Розсічення синехій порожнини носа у дорослого",
            price: "Від 4000 до 8000 грн",
          },
          {
            title: "Видалення кісти Торнвальда",
            price: "Від 10000 до 14000 грн",
          },
          {
            title: "Медикаментозний супровід при оперативному лікуванні (наркоз 1 година)",
            price: "2000 грн",
          },
          {
            title: "Кожна наступна година наркозу",
            price: "1000 грн",
          },
        ],
      },
    ],
  },
  {
    categoryTitle: "НЕВРОЛОГІЯ",
    isComplicated: false,
    subcategorys: [
      {
        services: [
          {
            title: "Консультація лікаря – невролога",
            price: "600 грн",
          },
          {
            title:
              "Повторна консультація лікаря – невролога (протягом 2 тижнів)",
            price: "400 грн",
          },
          {
            title:
              "Лікування больових синдромів при хронічній Мігрені з використанням ботоксу Ксеомін (ціна залежить від кількості використаного препарату)",
            price: "від 9000 до 12000 грн",
          },
          {
            title:
              "Лікування бруксизму (Ксеомін)",
            price: "від 7000 до 9000 грн",
          },
          {
            title: "Лікування нейрогенного болю (постгерпетична невралгія, тригемінальна невралгія, діабетична полінейропатія) 25 Од Ксеоміну",
            price: "2750 грн",
          },
          {
            title: "Лікування нейрогенного болю (постгерпетична невралгія, тригемінальна невралгія, діабетична полінейропатія) 50 Од Ксеоміну",
            price: "4000 грн",
          },
          {
            title:
              "Лікування нейрогенного болю (постгерпетична невралгія, тригемінальна невралгія, діабетична полінейропатія) 75 Од Ксеоміну",
            price: "5250 грн",
          },
          {
            title:
              "Лікування нейрогенного болю (постгерпетична невралгія, тригемінальна невралгія, діабетична полінейропатія) 100 Од Ксеоміну",
            price: "6500 грн",
          },
          {
            title:
              "Лікування міофасціального синдрому (блокада тригерних точок, одна точка) 5 Од Ксеоміну",
            price: "400 грн",
          },
          {
            title:
              "Лікування фантомних болей 150 Од Ксеоміну",
            price: "9000 грн",
          },
          {
            title:
              "Лікування фантомних болей 200 Од Ксеоміну",
            price: "12000 грн",
          },
          {
            title:
              "Лікування фантомних болей 250 Од Ксеоміну",
            price: "14500 грн",
          },
          {
            title:
              "Лікування  болей в куксі 150 Од Ксеоміну",
            price: "9000 грн",
          },
          {
            title:
              "Лікування  болей в куксі 200 Од Ксеоміну",
            price: "12000 грн",
          },
          {
            title:
              "Лікування  болей в куксі 250 Од Ксеоміну",
            price: "14500 грн",
          },
        ],
      },
    ],
  },
  {
    categoryTitle: "ГІНЕКОЛОГІЯ",
    isComplicated: true,
    subcategorys: [
      {
        subcategoryTile: "Діагностика",
        services: [
          {
            title: "Консультація гінеколога",
            price: "600 грн",
          },
          {
            title: "Повторна консультація гінеколога",
            price: "400 грн",
          },
          {
            title: "Кольпоскопія",
            price: "400 грн",
          },
          {
            title:
              "Консультація гінеколога + УЗД (трансвагінальне та/або трансабдомінальне)",
            price: "1000 грн",
          },
          {
            title:
              "УЗД органів малого тазу (трансвагінальне та/або трансабдомінальне)",
            price: "400 грн",
          },
          {
            title: "Рідинна цитологія ПАП-тест",
            price: "650 грн",
          },
          {
            title: "Класичний ПАП тест",
            price: "400 грн",
          },
          {
            title: "Дослідження мікрофлори урогенітального тракту",
            price: "400 грн",
          },
          {
            title: "Фолікулометрія",
            price: "300 грн",
          },
          {
            title: "Ехогістеросальпінгоскопія",
            price: "2900 грн",
          },
          {
            title: "Пайпель-біопсія ендометрію",
            price: "3200 грн",
          },
          {
            title:
              'Введення внутрішньоматкового контрацептиву "Мірена" (контрацептив включено до вартості)',
            price: "5700 грн",
          },
          {
            title:
              'Введення внутрішньоматкового контрацептиву "Мірена" (без вартості контрацептиву)',
            price: "3000 грн",
          },
          {
            title: "Видалення внутрішньоматкового контрацептиву",
            price: "800 грн",
          },
          {
            title: "Біопсія шийки матки з гістологічним дослідженням",
            price: "3500 грн",
          },
        ],
      },
      {
        subcategoryTile: "Оперативна гінекологія",
        services: [
          {
            title: "Коагуляція шийки матки",
            price: "3000 грн",
          },
          {
            title: "Видалення папілом зовнішніх статевих органів",
            price: "2000 грн",
          },
          {
            title: "Лікувально-діагностичне вишкрібання стінок порожнини матки",
            price: "4900 грн",
          },
          {
            title: "Конізація шийки матки",
            price: "6000 грн",
          },
          {
            title: "Видалення кісти піхви",
            price: "11900 грн",
          },
          {
            title: "Видалення кісти бартолінієвої залози",
            price: "11900 грн",
          },
          {
            title: "Гістероскопія",
            price: "11900 грн",
          },
          {
            title: "Видалення плацентарного поліпу",
            price: "11900 грн",
          },
          {
            title: "Видалення поліпу ендометрію",
            price: "11900 грн",
          },
          {
            title: "Ексцизія/коагуляція ділянок ендометріозу",
            price: "11900 грн",
          },
          {
            title: "Роз’єднання або висічення маткових сінехій",
            price: "11900 грн",
          },
          {
            title: "Абляція ендометрію",
            price: "11900 грн",
          },
          {
            title: "Біопсія ендометрію",
            price: "11900 грн",
          },
          {
            title: "Висічення внутрішньоматкової перегородки (метропластика)",
            price: "11900 грн",
          },
          {
            title: "Видалення сторонніх тіл з порожнини матки",
            price: "11900 грн",
          },
          {
            title: "Видалення субмукозного вузла",
            price: "11900 грн",
          },
          {
            title: "Пластика промежини",
            price: "18000 грн",
          },
          {
            title: "Задня кольпорафія",
            price: "28500 грн",
          },
          {
            title: "Передня кольпорафія",
            price: "24500 грн",
          },
          {
            title: "Повна вагінопластика",
            price: "47000 грн",
          },
          {
            title:
              "Медикаментозний супровід при оперативному лікуванні (наркоз)",
            price: "2000 грн",
          },
          {
            title: "Кожна наступна година наркозу",
            price: "1000 грн",
          },
        ],
      },
      {
        subcategoryTile: "Естетична гінекологія",
        services: [
          {
            title: "Лабіопластика (пластика статевих губ)",
            price: "24000 грн",
          },
          {
            title: "Нитьова перинеопластика (1 комплект)",
            price: "25000 грн",
          },
          {
            title: "Нитьова перинеопластика (2 комплекти)",
            price: "30000 грн",
          },
          {
            title: "Відновлення дівочої плеви",
            price: "33000 грн",
          },
          {
            title:
              "Корекція форми великих статевих губ за допомогою філлерів (1 мл)",
            price: "4400 грн",
          },
          {
            title: "Аугментація (збільшення) точки G",
            price: "4400 грн",
          },
          {
            title: "Біоревіталізація промежини",
            price: "2100 грн",
          },
          {
            title: "Біоревіталізація піхви",
            price: "2700 грн",
          },
          {
            title: "Контурна пластика піхви за допомогою філлерів (1 мл)",
            price: "2600 грн",
          },
          {
            title:
              "Корекція стресового нетримання сечі за допомогою філлерів (1 мл)",
            price: "4400 грн",
          },
          {
            title: "Інтимний пілінг",
            price: "1500 грн",
          },
          {
            title: "PRP – терапія",
            price: "від 2000 грн",
          },
          {
            title: "Інстиляції сечового міхура",
            price: "2200 грн",
          },
        ],
      },
    ],
  },
  {
    categoryTitle: "ПЛАСТИЧНА ХІРУРГІЯ",
    isComplicated: false,
    subcategorys: [
      {
        services: [
          {
            title: "Збільшення грудей круглими імплантами",
            price: "3000 євро",
          },
          {
            title: "Збільшення грудей анатомічними імплантами",
            price: "3500 євро",
          },
          {
            title: "Підтяжка грудей без імплантів",
            price: "3500 євро",
          },
          {
            title: "Підтяжка з імплантами",
            price: "5000 євро",
          },
          {
            title: "Заміна імплантів",
            price: "від 4000 євро",
          },
          {
            title: "Абдомінопластика",
            price: "2500 євро",
          },
          {
            title: "Абдомінопластика з ліпосакцією",
            price: "3000 євро",
          },
          {
            title: "Мініабдомінопластика",
            price: "2000 євро",
          },
          {
            title: "Ліпосакція живота",
            price: "1500 євро",
          },
          {
            title: "Ліпосакція поперекової зони (фланки)",
            price: "900 євро",
          },
          {
            title: "Ліпосакція передньої черевної стінки",
            price: "1200 євро",
          },
          {
            title: "Ліпосакція стегон",
            price: "900 євро",
          },
          {
            title: "Ліпосакція колін",
            price: "700 євро",
          },
          {
            title: "Ліпосакція підборіддя",
            price: "700 євро",
          },
          {
            title: "Ліпофілінг сідниць (одна сторона)",
            price: "1000 євро",
          },
          {
            title: "Ліпофілінг параорбітальної зони/носогубні складки/вилиці (вартість однієї зони)",
            price: "200 євро",
          },
          {
            title: "Ліпофілінг губ",
            price: "200-400 євро",
          },
          {
            title: "VY-пластика (збільшення верхньої губи без філерів)",
            price: "900 євро",
          },
          {
            title: "Хейлопластика (Булхорн)",
            price: "800 євро",
          },
          {
            title: "Верхня блефаропластика",
            price: "900 євро",
          },
          {
            title: "Нижня блефаропластика",
            price: "1100 євро",
          },
          {
            title: "Кругова блефаропластика",
            price: "1500 євро",
          },
          {
            title: "Фейсліфтинг SMAS",
            price: "2500 євро",
          },
          {
            title: "Отопластика однобічна",
            price: "600 євро",
          },
          {
            title: "Отопластика двобічна",
            price: "1000 євро",
          },
          {
            title: "Видалення грудок Біша",
            price: "900 євро",
          },
          {
            title: "Корекція рубця хірургічна (травматичні, післяопераційні)",
            price: "від 5000 грн",
          },
          {
            title: "Темпоральний ліфтинг",
            price: "800 євро",
          },
          {
            title:
              "*Розрахунок відбувається у гривні, курс обміну - актуальний курс НБУ",
          },
        ],
      },
    ],
  },
  {
    categoryTitle: "ХІРУРГІЯ",
    isComplicated: false,
    subcategorys: [
      {
        services: [
          {
            title: "Консультація хірурга",
            price: "600 грн",
          },
          {
            title: "Повторна консультація хірурга (протягом 2 місяців)",
            price: "400 грн",
          },
          {
            title:
              "Видалення дрібних елементів (папілом, точкових гемангіом тощо) – 1 утворення",
            price: "400 грн",
          },
          {
            title:
              "Видалення дрібних елементів (папілом, точкових гемангіом тощо) – 3 утворення",
            price: "800 грн",
          },
          {
            title:
              "Видалення дрібних елементів (папілом, точкових гемангіом тощо) – 5 утворень",
            price: "1100 грн",
          },
          {
            title:
              "Видалення дрібних елементів (папілом, точкових гемангіом тощо) – 10 утворень і більше",
            price: "2200 грн",
          },
          {
            title:
              "Видалення доброякісних новоутворень шкіри та підшкірної клітковини І категорії",
            price: "2000 грн",
          },
          {
            title:
              "Видалення доброякісних новоутворень шкіри та підшкірної клітковини ІІ категорії",
            price: "3000 грн",
          },
          {
            title:
              "Видалення доброякісних новоутворень шкіри та підшкірної клітковини ІІІ категорії",
            price: "4000 грн",
          },
          {
            title: "Зняття швів",
            price: "500 грн",
          },
          {
            title: "Обробка рани (ПХО)",
            price: "600 грн",
          },
          {
            title: "Обробка рани (ПХО) з накладанням швів",
            price: "1000 грн",
          },
          {
            title: "Розтин абсцесу, фурункулу І категорії",
            price: "1500 грн",
          },
          {
            title: "Розтин абсцесу, фурункулу ІІ категорії",
            price: "2500 грн",
          },
          {
            title: "Хірургічне лікування панарицію",
            price: "1500 грн",
          },
          {
            title: "Хірургічне лікування пароніхію",
            price: "1200 грн",
          },
          {
            title: "Хірургічне лікування при врослому нігті (крайова резекція з одного боку)",
            price: "1200 грн",
          },
          {
            title: "Хірургічне лікування при врослому нігті (крайова резекція з двох боків)",
            price: "1800 грн",
          },
          {
            title: "Хірургічне лікування пупкової кили",
            price: "6500 грн",
          },
          {
            title: "Хірургічне лікування кили білої лінії живота",
            price: "6500 грн",
          },
          {
            title: "Хірургічне лікування пахової кили",
            price: "8500 грн",
          },
          {
            title:
              "Патогістологічне дослідження видаленого новоутворення 1-2 категорії складності (до 3 утворень включно)",
            price: "1200 грн",
          },
          {
            title:
              "Патогістологічне дослідження видаленого новоутворення 3 категорії складності (до 3 утворень включно)",
            price: "1400 грн",
          },
          {
            title: "Медикаментозний супровід при оперативному лікуванні (наркоз 1 година)",
            price: "2000 грн",
          }, {
            title: "Кожна наступна година наркозу ",
            price: "1000 грн",
          },
        ],
      },
    ],
  },
  {
    categoryTitle: "ЕНДОКРИНОЛОГІЯ",
    isComplicated: false,
    subcategorys: [
      {
        services: [
          {
            title: "Консультація ендокринолога",
            price: "600 грн",
          },
          {
            title:
              "Повторна консультація ендокринолога (протягом 1 місяця)",
            price: "400 грн",
          },
          {
            title: "Консультація ендокринолога + УЗД щитоподібної залози",
            price: "900 грн",
          },
        ],
      },
    ],
  },
  {
    categoryTitle: "Дієтологія/Нутріціологія",
    isComplicated: false,
    subcategorys: [
      {
        services: [
          {
            title: "Консультація дієтолога (нутриціолога)",
            price: "600 грн",
          },
          {
            title:
              "Повторна консультація дієтолога (нутриціололга) (протягом 1 місяця)",
            price: "400 грн",
          },
        ],
      },
    ],
  },
];
