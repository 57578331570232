import {
  SERVICE_COSMETOLOGY_ROUTE,
  SERVICE_DERMATOLOGY_ROUTE,
  SERVICE_DIETICS_NUTRITION_ROUTE,
  SERVICE_ENDOCRINOLOGY_ROUTE,
  SERVICE_GINECOLOGY_ROUTE,
  SERVICE_NEUROLOGY_ROUTE,
  SERVICE_OTOLARYNGOLOGY_ROUTE,
  SERVICE_PASTIC_ROUTE,
  SERVICE_PROCTOLOGY_ROUTE,
  SERVICE_SURGERY_ROUTE,
  SERVICE_VESSELS_ROUTE,
} from "./routes";

import ginekologiaOne from "../assets/images/ginekologia-one.webp";
import ginekologiaTwo from "../assets/images/ginekologia-two.webp";
import ginekologiaThree from "../assets/images/ginekologia-three.webp";
import kosmetOne from "../assets/images/kosmetOne.webp";
import kosmetTwo from "../assets/images/kosmetTwo.webp";
import kosmetThree from "../assets/images/kosmetThree.webp";
import plasticOne from "../assets/images/plasticOne.webp";
import plasticTwo from "../assets/images/plasticTwo.webp";
import plasticThree from "../assets/images/plasticThree.webp";
import proctoOne from "../assets/images/proctoOne.webp";
import proctoTwo from "../assets/images/proctoTwo.webp";
import proctoThree from "../assets/images/proctoThree.webp";
import dermaOne from "../assets/images/dermaOne.webp";
import dermaTwo from "../assets/images/dermaTwo.webp";
import dermaThree from "../assets/images/dermaThree.webp";
import lorOne from "../assets/images/lorOne.webp";
import lorTwo from "../assets/images/lorTwo.webp";
import lorThree from "../assets/images/lorThree.webp";
import neuroThree from "../assets/images/neuroThree.webp";
import endoOne from "../assets/images/endocrinology1.webp";
import endoTwo from "../assets/images/dietology2.webp";
import endoThree from "../assets/images/endocrinology3.webp";
import dietOne from "../assets/images/dietology1.webp";
import dietTwo from "../assets/images/dietology2.webp";
import dietThree from "../assets/images/dietology3.webp";
import surgeryOne from "../assets/images/surgeryOne.webp";
import surgeryThree from "../assets/images/surgeryThree.webp";
import {
  textCosmetology,
  textDermatology,
  textDieteticsNutrition,
  textEndocrinology,
  textGinecology,
  textLor,
  textNeurology,
  textPlastic,
  textProctology,
  textSurgery,
} from "./texts";
import { title } from "framer-motion/client";

export const servicePageArr = [
  {
    path: SERVICE_DERMATOLOGY_ROUTE,
    metaTitle:
      "Дерматолог Чернігів - консультація лікаря-дерматолога МЦ “Filada” ",
    metaDescription:
      "Професійний дерматолог в Чернігові ᐉ Медичний центр “Філада” ⭐️ Консультація, Апаратні методики, Лікування рубців, PRP-терапія ✔️  Доступна ціна ✔️ Кращі лікарі ✔️ Професійний підхід",
    metaUrl: "https://filada.com.ua/dermatology/",
    coverDescription: "Дерматологія",
    abouteTitle: "Дерматологія",
    // seoText: textDermatology,
    abouteTexts: [
      `Захворювання шкіри мають безліч проявів, симптомів та наслідків. Кваліфікований лікар-дерматолог спеціалізується на діагностиці та лікуванні шкірних
      захворювань, нігтів та волосся. В медичному центрі Філада ви можете отримати повний спектр найсучаснішої та ефективної діагностики дерматологічних захворювань і отримати необхідне лікування.`,
      "Під час консультації лікар-дерматолог проводить опитування та огляд, в деяких випадках можуть бути призначені додаткові методи дослідження (загальні клінічні та біохімічні аналізи, дерматоскопія, взяття зіскрібків або біопсії шкіри для уточнення або підтвердження діагнозу).",
      "Після підтвердження діагнозу дерматолог рекомендує лікування. У сучасній дерматології використовують медикаментозні види лікування (місцеве або системне), фізіотерапевтичні, хірургічні або їх комплекс залежно від типу захворювання",
    ],
    aboutePhotos: [dermaTwo, dermaThree, dermaOne],
    scrollId: "ISuslova",
    pricesObj: {
      isComplicated: true,
      services: [
        {
          subCategory: "Діагностика",
          subCategoryServices: [
            {
              title: "Консультація лікаря-дерматолога",
              price: "600 грн",
            },
            {
              title: "Повторна консультація лікаря-дерматолога",
              price: "450 грн",
            },
          ],
        },
        {
          subCategory: "Апаратні методики",
          subCategoryServices: [
            {
              title: "Видалення судин діодним лазером (крила носа)",
              price: "1000 грн",
            },
            {
              title: "Видалення судин діодним лазером (ніс повністю)",
              price: "2000 грн",
            },
            {
              title: "Видалення судин діодним лазером (щоки)",
              price: "2600 грн",
            },
            {
              title:
                "Видалення судин діодним лазером (ноги, по тілу ділянка 10х10 см)",
              price: "2700 грн",
            },
            {
              title: "Видалення судин діодним лазером (підборіддя)",
              price: "1200 грн",
            },
            {
              title:
                "Видалення судин діодним лазером (ділянка над верхньою губою)",
              price: "1000 грн",
            },
            {
              title: "Видалення гемангіоми діодним лазером до 3 мм",
              price: "300 грн",
            },
            {
              title:
                "Видалення гемангіом діодним лазером від 10 шт по тілу (за шт)",
              price: "200 грн",
            },
            {
              title:
                "Видалення гемангіом діодним лазером від 10 шт (обличчя, статеві органи, за шт)",
              price: "250 грн",
            },
            {
              title:
                "Видалення гемангіом діодним лазером від 20 шт по тілу (за шт)",
              price: "150 грн",
            },
            {
              title:
                "Видалення гемангіом діодним лазером від 20 шт (обличчя, статеві органи, за шт)",
              price: "200 грн",
            },
            {
              title: "Одиночний імпульс",
              price: "8 грн",
            },
            {
              title: "Гідропілінг",
              price: "900 грн",
            },
            {
              title: "RF-ліфтинг неінвазивний",
              price: "900 грн",
            },
            {
              title: "Киснева мезотерапія",
              price: "900 грн",
            },
            {
              title: "Голівудський догляд",
              price: "1300 грн",
            },
          ],
        },
        {
          subCategory: "Лікування рубців полінуклеотидами",
          subCategoryServices: [
            {
              title: "Rejuran S (лікування рубців/стрій) 1 ml",
              price: "3300 грн",
            },
            {
              title:
                "Plinest one(лікування стріїв,рубців та ліфтинг шкіри тіла) ",
              price: "1650 грн",
            },
            {
              title:
                "Лікування рубців ферментами Pbserum  HA 1.5 HIGH",
              price: "8500 грн",
            },
            {
              title:
                "Лікування рубців ферментами Pbserum",
              price: "2500 грн",
            },
          ],
        },
        {
          subCategory: "PRP-терапія ",
          subCategoryServices: [
            {
              title: "PRP-терапія обличчя (2 пробірки)",
              price: "2200 грн",
            },
            {
              title: "PRP-терапія волосистої частини голови (2 пробірки)",
              price: "2200 грн",
            },
          ],
        },
      ],
    },
  },
  {
    path: SERVICE_COSMETOLOGY_ROUTE,
    metaTitle:
      "Косметолог Чернігів - Професійна косметологія в клініці “Filada” ",
    metaDescription:
      "Послуги косметолога в Чернігові ᐉ Медичний центр “Філада” ⭐️  Доступна ціна ✔️ Кращі лікарі ✔️ Професійний підхід ✔️ Всі види косметологічних послуг. 063-503-04-72",
    metaUrl: "https://filada.com.ua/cosmetology/",
    coverDescription: "Косметологія",
    abouteTitle: "Косметологія",
    // seoText: textCosmetology,
    abouteTexts: [
      `Людина, яка доглядає за собою, завжди виділяється серед інших. До косметолога звертаються з різних причин: - Для когось важливий презентабельний зовнішній вигляд - Комусь це допомагає відчувати себе впевненіше в особистому чи професійному житті - Хтось слідкує за трендами і бажає спробувати новітні процедури - А хтось хоче виглядати молодше свого віку`,
      "Не залежно від того, яка мета саме у вас, ми точно знаємо одне – наші фахівці допоможуть її досягнути в найближчий час. Адже в медичному центрі FILADA ми проводимо безпечні та якісні косметологічні процедури різного спектру та направленості дії.",
      "Записуйтесь на консультацію, щоб отримати індивідуальну програму по догляду та/або лікуванню. Ми не просто проводимо процедури, ми даруємо нашим клієнтам красу, молодість та радість життя.",
    ],
    aboutePhotos: [kosmetOne, kosmetTwo, kosmetThree],
    scrollId: "ISuslova",
    pricesObj: {
      isComplicated: true,
      services: [
        {
          subCategory: "Діагностика",
          subCategoryServices: [
            {
              title: "Консультація косметолога",
              price: "550 грн",
            },
          ],
        },
        {
          subCategory: "Ін’єкційна косметологія (ботулінотерапія)",
          subCategoryServices: [
            {
              title: "Ботулінотерапія одна зона",
              price: "2000 грн",
            },
            {
              title: "Ботулінотерапія Лоб+міжбрів’я",
              price: "3850 грн",
            },
            {
              title: "Ботулінотерапія Лоб+міжбрів’я+очі",
              price: "5500 грн",
            },
            {
              title: "Ботулінотерапія Full face",
              price: "7700 грн",
            },
            {
              title: "Ботулінотерапія  Платізма (шия)",
              price: "6600 грн",
            },
            {
              title: "Лікування гіпергідрозу (Диспорт)",
              price: "5500 грн",
            },
            
          ],
        },
        {
          subCategory: "Ін’єкційна косметологія (бланшинг)",
          subCategoryServices: [
            {
              title: "Бланшинг поверхневих зморшок (Belotero Balance)",
              price: "6600 грн",
            },
            {
              title: "Бланшинг поверхневих зморшок (Belotero Soft)",
              price: "6600 грн",
            },
          ],
        },
        {
          subCategory: "Ін’єкційна косметологія (контурна пластика)",
          subCategoryServices: [
            {
              title: "Зона губ Belotero 0,6 ml",
              price: "4500 грн",
            },
            {
              title: "Зона губ Belotero 1 ml",
              price: "5500 грн",
            },
            {
              title: "Зона губ Pluryal 1 ml",
              price: "5000 грн",
            },
            {
              title: "Зона губ Saypha 1ml",
              price: "4000 грн",
            },
            {
              title: "Зона вилиць/підборіддя та кути нижної щелепи Belotero 1 ml",
              price: "6600 грн",
            },
            {
              title: "Зона вилиць/підборіддя та кути нижної щелепи Saypha 1 ml",
              price: "4500 грн",
            },
            {
              title: "Зона вилиць/підборіддя та кути нижної щелепи Pluryal 1 ml",
              price: "5500 грн",
            },
            {
              title: "Зона губ Juvederm 0.6 мл",
              price: "4800 грн",
            },
            {
              title: "Зона губ Juvederm 1.0 мл",
              price: "6500 грн",
            },
            {
              title: "Зона вилиць Juvederm 1.0 мл",
              price: "7100 грн",
            },
            {
              title: "Зона підборіддя/нижня щелепа борозди Juvederm 1.0 мл",
              price: "7000 грн",
            },
            {
              title: "Зона підборіддя/нижня щелепа борозди Juvederm 0.6 мл",
              price: "6500 грн",
            },
            {
              title: "Розчинення філера препаратом PB-serum",
              price: "1550 грн",
            },
          
          ],
        },
        {
          subCategory:
            "Ін’єкційна косметологія (Біоревіталізація/Біорепарація)",
          subCategoryServices: [
            {
              title: "Біоревіталізація/Біорепарація обличчя Saypha Rich 1 ml",
              price: "3300 грн",
            },
            {
              title: "Біоревіталізація/Біорепарація обличчя Belotero Revive 1 ml",
              price: "4400 грн",
            },
            {
              title: "Біоревіталізація/Біорепарація обличчя Rejuran HB 1 ml",
              price: "3850 грн",
            },
            {
              title: "Біоревіталізація/Біорепарація обличчя HYALUAL Electri 1.5 ml",
              price: "3100 грн",
            },
            {
              title: "Біоревіталізація/Біорепарація обличчя HYALUAL Smart 1.8% 1ml",
              price: "3300 грн",
            },
            {
              title: "Біоревіталізація/Біорепарація обличчя HYALUAL booster 2.5 % 1 ml",
              price: "3500 грн",
            },
            {
              title: "Біоревіталізація/Біорепарація (обличча+шия +декольте/руки) TwAc 2.0 3ml",
              price: "5200 грн",
            },
            {
              title: "Ін'єккційна косметологія (Біореві+26:46талізація/Біорепарація)",
              price: "5500 грн",
            },
            {
              title: "Біоревіталізація/Біорепарація (обличча+шия +декольте/руки) Plinest (Mastelli) 2 ml",
              price: "4600 грн",
            },
            {
              title: "Біоревіталізація/Біорепарація (обличча+шия +декольте/руки) HP CELL Vitaran і II 2 ml",
              price: "3500 грн",
            },
            {
              title: "Біоревіталізація/Біорепарація (обличча+шия +декольте/руки) HP CELL Vitaran Whitening &Anti-aging 2 мл",
              price: "4300 грн",
            },
            {
              title: "Біоревіталізація/Біорепарація (обличча+шия +декольте/руки) Neauvia Hidro Delux 2.5 ml",
              price: "3300 грн",
            },
            {
              title: "Біоревіталізація/Біорепарація (обличча+шия +декольте/руки) Newest (Mastelli) 2 ml",
              price: "4900 грн",
            },
            {
              title: "Біоревіталізація/Біорепарація (обличча+шия +декольте/руки) HYALUAL Smart 1.8% 2 ml",
              price: "3900 грн",
            },
            {
              title: "Біоревіталізація/Біорепарація (обличча+шия +декольте/руки) HYALUAL booster 2.5% 2 ml",
              price: "4200 грн",
            },
            {
              title: "Біоревіталізація/Біорепарація періорбітальної зони Plinest eye 2 ml",
              price: "4500 грн",
            },
            {
              title: "Біоревіталізація/Біорепарація періорбітальної зони Rejuran I 1 ml",
              price: "3300 грн",
            },
            {
              title: "Біоревіталізація/Біорепарація періорбітальної зони TwAc eyes1 ml",
              price: "3100 грн",
            },
            {
              title: "Біоревіталізація/Біорепарація періорбітальної зони HP CELL Vitaran і 1 ml",
              price: "2600 грн",
            },
            {
              title: "Біорепарація+Нанохілінг(обличча+шия+декольте/руки/тіло) Hyalual Perfoskin",
              price: "3500 грн",
            },
            {
              title: "RRS long lastic 3 ml",
              price: "4500 грн",
            },
            {
              title: "Біоревіталізація/Біорепарація(обличча+шия+декольте/руки) RRS HYALIFT 75 proactive 5 ml",
              price: "2800 грн",
            },
          ],
        },
        {
          subCategory: "Мезотерапія",
          subCategoryServices: [
            {
              title: "Мезотерапія періорбітальної зони RRS HA Eyes",
              price: "1200 грн",
            },
            {
              title: "Мезотерапія періорбітальної зони Dermaheal Dark Circle",
              price: "1900 грн",
            },
            {
              title:
                "Мезотерапія волосистої частини голови XL HAIR",
              price: "1600 грн",
            },
            {
              title: "Мезотерапія волосистої частини голови DR. CYJ",
              price: "3600 грн",
            },
            {
              title: "Мезотерапія волосистої частини голови Dermaheal HL",
              price: "1100 грн",
            },
          ],
        },
        {
          subCategory: "Ліполітична терапія",
          subCategoryServices: [
            {
              title: "Ліполітична терапія PBserum",
              price: "3000 грн",
            },
            {
              title: "Ліполітична терапія RRS HA CELLUTRIX",
              price: "2000 грн",
            },
          ],
        },
        {
          subCategory: "Доглядова косметологія",
          subCategoryServices: [
            {
              title: "Комбінована чистка обличчя",
              price: "950 грн",
            },
            {
              title: "Комбінована чистка обличчя + пілінги",
              price: "1100 грн",
            },
            {
              title: "Карбоксітерапія",
              price: "800 грн",
            },
            {
              title: "Експрес догляд(по типу шкіри)",
              price: "800 грн",
            },
            {
              title: "Масаж обличчя, шиї та декольте",
              price: "700 грн",
            },
            {
              title: "Вогонь та лід IS Clinical",
              price: "1400 грн",
            },
            {
              title: "Платиновий антивіковий догляд  MEDIK 8",
              price: "1100 грн",
            },
            {
              title: "Механічна чистка спини+пілінг",
              price: "1400 грн",
            },
            {
              title: "Пілінг мигдалевий",
              price: "900 грн",
            },
            {
              title: "Пілінг азелаїновий",
              price: "900 грн",
            },
            {
              title: "Пілінг освітлюючий",
              price: "900 грн",
            },
            {
              title: "Ретиноловий пілінг периорбітальної зони",
              price: "900 грн",
            },
            {
              title:
                "Поверхнево-серединний пілінг з біоревіталізуючим ефектом  Appex ",
              price: "1700 грн",
            },
            {
              title:
                "Поверхнево-серединний пілінг з біоревіталізуючим ефектом  PRX33 ",
              price: "1800 грн",
            },
          ],
        },
      ],
    },
  },
  {
    path: SERVICE_GINECOLOGY_ROUTE,
    metaTitle: "Гінеколог в Чернігові ᐉ консультація, запис - Filada",
    metaDescription:
      "Запис до Гінеколога в Чернігові ᐉ Клініка Filada ⭐ Консультація гінеколога ✔️ Огляд ✔️ Аналізи ✔️ Доступна ціна ✔️ Краще лікування ✔️ PRP – терапія. Тел.063-503-04-72",
    metaUrl: "https://filada.com.ua/gynecology/",
    coverDescription: "Гінекологія",
    abouteTitle: "Гінеколог в Чернігові",
    seoText: textGinecology,
    abouteTexts: [
      "Чи знаєте ви, як часто кожна жінка повинна відвідувати гінеколога? Мінімум 1 раз на рік . Для того, щоб виявляти відхилення від норми на ранніх стадіях та проводити профілактику різних захворювань.",
      "А якщо проблема з жіночим здоров’ям вже є, то не варто зволікати з її вирішенням. Адже при несвоєчасному лікуванні значно зростає вірогідність різних ускладнень.",
      "В нашому медичному центрі FILADA ми готові вирішувати ваші гінекологічні проблеми своєчасно, використовуючи при цьому сучасне обладнання та новітні методики.",
    ],
    aboutePhotos: [ginekologiaOne, ginekologiaTwo, ginekologiaThree],
    scrollId: "VTsikal",
    pricesObj: {
      isComplicated: true,
      services: [
        {
          subCategory: "Діагностика",
          subCategoryServices: [
            {
              title: "Консультація гінеколога",
              price: "600 грн",
            },
            {
              title: "Повторна консультація гінеколога",
              price: "400 грн",
            },
            {
              title: "Кольпоскопія",
              price: "400 грн",
            },
            {
              title:
                "Консультація гінеколога + УЗД (трансвагінальне та/або трансабдомінальне)",
              price: "1000 грн",
            },
            {
              title:
                "УЗД органів малого тазу (трансвагінальне та/або трансабдомінальне)",
              price: "400 грн",
            },
            {
              title: "Рідинна цитологія ПАП-тест",
              price: "650 грн",
            },
            {
              title: "Класичний ПАП тест",
              price: "400 грн",
            },
            {
              title: "Дослідження мікрофлори урогенітального тракту",
              price: "400 грн",
            },
            {
              title: "Фолікулометрія",
              price: "300 грн",
            },
            {
              title: "Ехогістеросальпінгоскопія",
              price: "2900 грн",
            },
            {
              title: "Пайпель-біопсія ендометрію",
              price: "3200 грн",
            },
            {
              title:
                'Введення внутрішньоматкового контрацептиву "Мірена" (контрацептив включено до вартості)',
              price: "5700 грн",
            },
            {
              title:
                'Введення внутрішньоматкового контрацептиву "Мірена" (без вартості контрацептиву)',
              price: "3000 грн",
            },
            {
              title: "Видалення внутрішньоматкового контрацептиву",
              price: "800 грн",
            },
            {
              title: "Біопсія шийки матки з гістологічним дослідженням",
              price: "3500 грн",
            },
          ],
        },
        {
          subCategory: "Оперативна гінекологія",
          subCategoryServices: [
            {
              title: "Коагуляція шийки матки",
              price: "3000 грн",
            },
            {
              title: "Видалення папілом зовнішніх статевих органів",
              price: "2000 грн",
            },
            {
              title:
                "Лікувально-діагностичне вишкрібання стінок порожнини матки",
              price: "4900 грн",
            },
            {
              title: "Конізація шийки матки",
              price: "6000 грн",
            },
            {
              title: "Видалення кісти піхви",
              price: "11900 грн",
            },
            {
              title: "Видалення кісти бартолінієвої залози",
              price: "11900 грн",
            },
            {
              title: "Гістероскопія",
              price: "11900 грн",
            },
            {
              title: "Видалення плацентарного поліпу",
              price: "11900 грн",
            },
            {
              title: "Видалення поліпу ендометрію",
              price: "11900 грн",
            },
            {
              title: "Ексцизія/коагуляція ділянок ендометріозу",
              price: "11900 грн",
            },
            {
              title: "Роз’єднання або висічення маткових сінехій",
              price: "11900 грн",
            },
            {
              title: "Абляція ендометрію",
              price: "11900 грн",
            },
            {
              title: "Біопсія ендометрію",
              price: "11900 грн",
            },
            {
              title: "Висічення внутрішньоматкової перегородки (метропластика)",
              price: "11900 грн",
            },
            {
              title: "Видалення сторонніх тіл з порожнини матки",
              price: "11900 грн",
            },
            {
              title: "Видалення субмукозного вузла",
              price: "11900 грн",
            },
            {
              title: "Пластика промежини",
              price: "18000 грн",
            },
            {
              title: "Задня кольпорафія",
              price: "28500 грн",
            },
            {
              title: "Передня кольпорафія",
              price: "24500 грн",
            },
            {
              title: "Повна вагінопластика",
              price: "47000 грн",
            },
            {
              title:
                "Медикаментозний супровід при оперативному лікуванні (наркоз)",
              price: "2000 грн",
            },
            {
              title: "Кожна наступна година наркозу",
              price: "1000 грн",
            },
          ],
        },
        {
          subCategory: "Естетична гінекологія",
          subCategoryServices: [
            {
              title: "Лабіопластика (пластика статевих губ)",
              price: "24000 грн",
            },
            {
              title: "Нитьова перинеопластика (1 комплект)",
              price: "25000 грн",
            },
            {
              title: "Нитьова перинеопластика (2 комплекти)",
              price: "30000 грн",
            },
            {
              title: "Відновлення дівочої плеви",
              price: "33000 грн",
            },
            {
              title:
                "Корекція форми великих статевих губ за допомогою філлерів (1 мл)",
              price: "4400 грн",
            },
            {
              title: "Аугментація (збільшення) точки G",
              price: "4400 грн",
            },
            {
              title: "Біоревіталізація промежини",
              price: "2100 грн",
            },
            {
              title: "Біоревіталізація піхви",
              price: "2700 грн",
            },
            {
              title: "Контурна пластика піхви за допомогою філлерів (1 мл)",
              price: "2600 грн",
            },
            {
              title:
                "Корекція стресового нетримання сечі за допомогою філлерів (1 мл)",
              price: "4400 грн",
            },
            {
              title: "Інтимний пілінг",
              price: "1500 грн",
            },
            {
              title: "PRP – терапія",
              price: "від 2000 грн",
            },
            {
              title: "Інстиляції сечового міхура",
              price: "2200 грн",
            },
          ],
        },
      ],
    },
  },
  {
    path: SERVICE_PASTIC_ROUTE,
    metaTitle:
      "Пластична хірургія в Чернігові ᐉ Консультація пластичного хірурга - Filada",
    metaDescription:
      "Пластична хірургія в Чернігові ᐉ Клініка Filada ⭐ Консультація пластичного хірурга ✔️ Ринопластика ✔️ Збільшення грудей ✔️ Професійний підхід ✔️ Найкращі хірурги. Тел.063-503-04-72",
    metaUrl: "https://filada.com.ua/plastic-surgery/",
    coverDescription: "Пластична хірургія",
    abouteTitle: "Пластична хірургія",
    seoText: textPlastic,
    abouteTexts: [
      "Як пластична хірургія може змінити ваше життя на краще? Якщо ваш зовнішній вигляд вас не задовольняє. Якщо ваш внутрішній світ не сприймає вашу зовнішність, ваше тіло, то ви в правильному місці. Адже ми вважаємо, що саме Ви заслуговуєте бути щасливими та успішними.",
      "А в цьому вам аж ніяк не обійтись без гарної зовнішності, красивого носика, свіжих не втомлених очей, пружних наповнених грудей та підтягнутого тіла. Кожен обирає для себе те, що йому необхідно.",
      "В медичному центрі FILADA ми використовуємо найсучасніші методики та підходи до корекції зовнішнього вигляду, щоб не просто задовольнити всі ваші потреби та запити, а і зробити це максимально безпечно та комфортно. Всі ваші мрії можуть стати реальністю вже найближчим часом. Запишіться на безкоштовну консультацію до нашого пластичного хірурга та зробіть перший крок на зустріч до вашої мрії!",
    ],
    aboutePhotos: [plasticOne, plasticTwo, plasticThree],
    scrollId: "OKosenko",
    pricesObj: {
      isComplicated: false,
      services: [
        {
          title: "Збільшення грудей круглими імплантами",
          price: "3000 євро",
        },
        {
          title: "Збільшення грудей анатомічними імплантами",
          price: "3500 євро",
        },
        {
          title: "Підтяжка грудей без імплантів",
          price: "3500 євро",
        },
        {
          title: "Підтяжка з імплантами",
          price: "5000 євро",
        },
        {
          title: "Заміна імплантів",
          price: "від 4000 євро",
        },
        {
          title: "Абдомінопластика",
          price: "2500 євро",
        },
        {
          title: "Абдомінопластика з ліпосакцією",
          price: "3000 євро",
        },
        {
          title: "Мініабдомінопластика",
          price: "2000 євро",
        },
        {
          title: "Ліпосакція живота",
          price: "1500 євро",
        },
        {
          title: "Ліпосакція поперекової зони (фланки)",
          price: "900 євро",
        },
        {
          title: "Ліпосакція передньої черевної стінки",
          price: "1200 євро",
        },
        {
          title: "Ліпосакція стегон",
          price: "900 євро",
        },
        {
          title: "Ліпосакція колін",
          price: "700 євро",
        },
        {
          title: "Ліпосакція підборіддя",
          price: "700 євро",
        },
        {
          title: "Ліпофілінг сідниць (одна сторона)",
          price: "1000 євро",
        },
        {
          title:
            "Ліпофілінг параорбітальної зони/носогубні складки/вилиці (вартість однієї зони)",
          price: "200 євро",
        },
        {
          title: "Ліпофілінг губ",
          price: "200-400 євро",
        },
        {
          title: "VY-пластика (збільшення верхньої губи без філерів)",
          price: "900 євро",
        },
        {
          title: "Хейлопластика (Булхорн)",
          price: "800 євро",
        },
        {
          title: "Верхня блефаропластика",
          price: "900 євро",
        },
        {
          title: "Нижня блефаропластика",
          price: "1100 євро",
        },
        {
          title: "Кругова блефаропластика",
          price: "1500 євро",
        },
        {
          title: "Фейсліфтинг SMAS",
          price: "2500 євро",
        },
        {
          title: "Отопластика однобічна",
          price: "600 євро",
        },
        {
          title: "Отопластика двобічна",
          price: "1000 євро",
        },
        {
          title: "Видалення грудок Біша",
          price: "900 євро",
        },
        {
          title: "Корекція рубця хірургічна (травматичні, післяопераційні)",
          price: "від 5000 грн",
        },
        {
          title: "Темпоральний ліфтинг",
          price: "800 євро",
        },
        {
          title:
            "*Розрахунок відбувається у гривні, курс обміну - актуальний курс НБУ",
        },
      ],
    },
  },
  {
    path: SERVICE_PROCTOLOGY_ROUTE,
    metaTitle: "Проктолог в Чернігові ᐉ консультація, запис - Filada",
    metaDescription:
      "Консультація Проктолога в Чернігові ᐉ Клініка Filada ⭐ Запис до проктолога ✔️ Ефективне лікування та безболісна діагностика ✔️ Доступна ціна. Тел.063-503-04-72",
    metaUrl: "https://filada.com.ua/proctology/",
    coverDescription: "Проктологія",
    abouteTitle: "Проктологія в Чернігові",
    seoText: textProctology,
    abouteTexts: [
      "Найпоширеніше упередження при виникненні проблем з боку заднього проходу – це «соромно прийти на огляд».  Насправді наші комплекси роблять нам ведмежу послугу. Проблема не вирішується, а тільки погіршується з року в рік. В результаті вирішити її без операції стає вкрай важко або не можливо. Але якщо вже захворювання потребує оперативного лікування, то найкращим рішенням буде виконати це із застосуванням сучасних методик, які не потребують тривалого перебування в стаціонарі, безпечні та малотравматичні, а отже і практично безболісні.",
      "В нашому медичному центрі FILADA ми використовуємо HAL-RAR (HAL – гемороїдальне артеріальне лігування, RAR – ректо-анальна реконструкція) – це метод лікування, спрямований на усунення першопричин геморою, тобто лігування патологічно змінених кровоносних судин, розташованих в прямій кишці. Ця методика є малоінвазивною альтернативою традиційним хірургічним процедурам, при застосуванні яких пацієнт має знаходитися в стаціонарі декілька днів або навіть тижнів, а весь післяопераційний період може становити від 25 до 45 днів.",
      "На відміну від класичних хірургічних втручань операція HAL-RAR виконується за допомогою апарату TRILOGY без розрізів. Період перебування в стаціонарі 1-2 дні, а період реабілітації складає всього декілька днів.",
    ],
    aboutePhotos: [proctoOne, proctoTwo, proctoThree],
    scrollId: "EIvashyn",
    pricesObj: {
      isComplicated: false,
      services: [
        {
          title: "Консультація проктолога",
          price: "600 грн",
        },
        {
          title: "Повторна консультація проктолога (протягом 2 тижнів)",
          price: "400 грн",
        },
        {
          title: "Аноскопія",
          price: "700 грн",
        },
        {
          title: "Ректороманоскопія",
          price: "1200 грн",
        },
        {
          title:
            "Видалення зовнішнього гемороїдального вузла І категорії складності",
          price: "4500 грн",
        },
        {
          title:
            "Видалення зовнішнього гемороїдального вузла IІ категорії складності",
          price: "5300 грн",
        },
        {
          title:
            "Видалення зовнішнього гемороїдального вузла IІІ категорії складності",
          price: "6300 грн",
        },
        {
          title:
            "Видалення доброякісних новоутворень підвищеної складності (анальна бахромка)",
          price: "5700 грн",
        },
        {
          title: "Видалення анального поліпу I категорії складності",
          price: "3100 грн",
        },
        {
          title: "Видалення анального поліпу IІ категорії складності",
          price: "4200 грн",
        },
        {
          title: "Видалення анального поліпу IІІ категорії складності",
          price: "5300 грн",
        },
        {
          title:
            "Видалення тромбу гемороїдального вузла I категорії складності",
          price: "3800 грн",
        },
        {
          title:
            "Видалення тромбу гемороїдального вузла IІ категорії складності",
          price: "4500 грн",
        },
        {
          title:
            "Видалення тромбу гемороїдального вузла IІІ категорії складності",
          price: "5300 грн",
        },
        {
          title: "Коагуляція гіпертрофованої анальної залози (папілліт)",
          price: "3000 грн",
        },
        {
          title: "Видалення стороннього тіла з анального каналу",
          price: "5200 грн",
        },
        {
          title: "Оперативне лікування анальної тріщини",
          price: "9000 грн",
        },
        {
          title: "Оперативне лікування складних анальних тріщин (двох)",
          price: "14000 грн",
        },
        {
          title:
            "Оперативне лікування складних анальних тріщин зі спазмом сфінктера",
          price: "15500 грн",
        },
        {
          title:
            "Оперативне лікування внутрішнього геморою радіохвильовим методом",
          price: "14000 грн",
        },
        {
          title:
            "Оперативне лікування внутрішнього геморою з використанням методики HAL-RAR",
          price: "32000 грн",
        },
        {
          title: "Медикаментозний супровід при оперативному лікуванні (наркоз)",
          price: "2000 грн",
        },
        {
          title: "Кожна наступна година наркозу",
          price: "1000 грн",
        },
      ],
    },
  },
  {
    path: SERVICE_OTOLARYNGOLOGY_ROUTE,
    metaTitle: "ЛОР в Чернігові ᐉ консультація, запис - Filada",
    metaDescription:
      "Консультація ЛОРа в Чернігові ᐉ Клініка Filada ⭐ Запис до ЛОРа ✔️ Доступна ціна. Тел.063-503-04-72",
    metaUrl: "https://filada.com.ua/otolaryngology/",
    coverDescription: "ЛОР",
    abouteTitle: "ЛОР в Чернігові",
    // seoText: textLor,
    abouteTexts: [
      "Порушення носового дихання, біль, виділення з носу, порушення слуху, утруднене ковтання, викривлення носової перетинки та інші симптоми можуть свідчити про наявність захворювання ЛОР органів. Не варто чекати ускладнень.",
      "В медичному центрі Філада застосовуються найсучасніщі методи діагностики та лікування захворювань верхніх дихальних шляхів та слухового апарату. Це дозволяє визначити точний діагноз, встановити причину ЛОР-патології та призначити ефективне індивідуальне лікування для пацієнта.",
      "Консультативний прийом і операції на ЛОР органах у нашому центрі виконуються з мінімальним дискомфортом і максимальним ефектом для наших пацієнтів! Більшість оперативних втручань при ЛОР захворюваннях виконуються без госпіталізації. В медичному центрі Філада найскладніші хірургічні завдання вирішуються всього за 1 день, завдяки застосуванню відео ендоскопів та малоінвазивних методик оперативного лікування. У разі необхідності госпіталізації, до послуг наших пацієнтів комфортний стаціонар.",
    ],
    aboutePhotos: [lorOne, lorTwo, lorThree],
    scrollId: "HMekshyn",
    pricesObj: {
      isComplicated: false,
      services: [
        {
          title: "Консультація ЛОРа",
          price: "600 грн",
        },
        {
          title: "Консультація оториноларинголога з ендоскопічним обстеженням",
          price: "900 грн",
        },
        {
          title: "Повторна консультація ЛОРа (протягом 1 місяця)",
          price: "450 грн",
        },
        {
          title: "Промивання лакун мигдаликів",
          price: "450 грн",
        },
        {
          title: "Продування слухових труб по Політцеру",
          price: "145 грн",
        },
        {
          title: "Вливання в гортань лікарських засобів",
          price: "290 грн",
        },
        {
          title: "Видалення сірчаної пробки 1 вухо",
          price: "350 грн",
        },
        {
          title: "Туалет носа",
          price: "350 грн",
        },
        {
          title: "Туалет вуха",
          price: "350 грн",
        },
        {
          title:
            "Катеризація слухової труби з введенням лікарських засобів (з однієї сторони)",
          price: "400 грн",
        },
        {
          title: "Тимпанопункція",
          price: "650 грн",
        },
        {
          title: "Пункція верхньощелепної пазухи",
          price: "800 грн",
        },
        {
          title: "ПХО рани( якщо буде запит)",
          price: "від 800 грн",
        },
        {
          title:
            "Хемокаустика (припікання) судин носа, що кровоточать (трихлороцтовою кислотою)",
          price: "500 грн",
        },
        {
          title: "Діатермокоагуляція судин носа",
          price: "700 грн",
        },
        {
          title: "Передня тампонада носа тампоном «мероцель», 1 тампон",
          price: "400 грн",
        },
        {
          title: "Передня тампонада носа марлевим тампоном",
          price: "300 грн",
        },
        {
          title: "Видалення тампонів з носа",
          price: "300 грн",
        },
        {
          title: "Видалення чужорідного тіла з порожнини носа, глотки, вуха",
          price: "500 грн",
        },
        {
          title: "Видалення доброякісних новоутворень ЛОР-органів",
          price: "від 1200 грн",
        },
        {
          title: "Розтин паратонзилярного або парафаренгіального абсцесу",
          price: "1200 грн",
        },
        {
          title: "Ревізія абсцесу",
          price: "500 грн",
        },
        {
          title:
            "Розтин фурункулів зовнішнього носа або зовнішнього вуха, розтин кісти піднебінного мидалика І кат.",
          price: "500 грн",
        },
        {
          title:
            "Розтин фурункулів зовнішнього носа або зовнішнього вуха, розтин кісти піднебінного мигдалика ІІ кат.",
          price: "800 грн",
        },
        {
          title: "Ревізія фурункула",
          price: "300 грн",
        },
        {
          title: "Міринготомія/парацентез",
          price: "1000 грн",
        },
        {
          title: "Взяття біопсії з носа, без вартості ПГД",
          price: "від 650 грн",
        },
        {
          title: "Взяття біопсії з глотки, без вартості ПГД",
          price: "650 грн",
        },
        {
          title: "Розкриття гематоми вуха, носу",
          price: "700 грн",
        },
        {
          title: "Шунтування барабанної перетинки (з однієї сторони)",
          price: "7000 грн",
        },
        {
          title: "Підслизова вазотомія нижніх носових раковин",
          price: "8000 грн",
        },
        {
          title:
            "Ендоскопічна вазотомія з ощадливою конхотомією задніх кінців нижніх носових раковин",
          price: "12000 грн",
        },
        {
          title: "Септопластика",
          price: "12000 грн",
        },
        {
          title: "Ендоскопічна септопластика",
          price: "18000 грн",
        },
        {
          title: "Ендоскопічна гайморотомія однобічна ( з видаленням кісти)",
          price: "15000 грн",
        },
        {
          title:
            "Ендоскопічна гайморотомія однобічна ( з видаленням міцетоми/стороннього тіла)",
          price: "18000 грн",
        },
        {
          title: "Ендоскопічна шейверна поліпетмоїдотомія",
          price: "Від 12000 до 20000 грн",
        },
        {
          title: "Поліпотомія у дорослого класичним методом",
          price: "8000 грн",
        },
        {
          title: "Розсічення синехій порожнини носа у дорослого",
          price: "Від 4000 до 8000 грн",
        },
        {
          title: "Видалення кісти Торнвальда",
          price: "Від 10000 до 14000 грн",
        },
        {
          title:
            "Медикаментозний супровід при оперативному лікуванні (наркоз 1 година)",
          price: "2000 грн",
        },
        {
          title: "Кожна наступна година наркозу",
          price: "1000 грн",
        },
      ],
    },
  },
  {
    path: SERVICE_NEUROLOGY_ROUTE,
    metaTitle: "Невролог в Чернігові ᐉ консультація, запис - Filada",
    metaDescription:
      "Консультація невролога в Чернігові ᐉ Клініка Filada ⭐ Запис до невролога ✔️ Доступна ціна. Тел.063-503-04-72",
    metaUrl: "https://filada.com.ua/neurology/",
    coverDescription: "Неврологія",
    abouteTitle: "Невролог в Чернігові",
    seoText: textNeurology,
    abouteTexts: [
      "Неврологія – це галузь медицини, яка відповідає за діагностику та лікування різноманітних захворювань центральної та периферичної нервової системи.",
      "Неврологічні захворювання дуже розповсюджені серед населення, мають дуже багато різноманітних проявів та симптомів. І лише кваліфікований лікар-невролог може встановити правильний діагноз та призначити ефективне лікування, спираючись на сучасні міжнародні рекомендації.",
      "Саме в медичному центрі Філада ви можете отримати всю необхідну допомогу.",
    ],
    aboutePhotos: [lorOne, proctoTwo, neuroThree],
    scrollId: "YKosenko",
    pricesObj: {
      isComplicated: false,
      services: [
        {
          title: "Консультація лікаря – невролога",
          price: "600 грн",
        },
        {
          title:
            "Повторна консультація лікаря – невролога (протягом 2 тижнів)",
          price: "400 грн",
        },
        {
          title:
            "Лікування больових синдромів при хронічній Мігрені з використанням ботоксу Ксеомін (ціна залежить від кількості використаного препарату)",
          price: "від 9000 до 12000 грн",
        },
        {
          title:
            "Лікування бруксизму (Ксеомін)",
          price: "від 7000 до 9000 грн",
        },
        {
          title: "Лікування нейрогенного болю (постгерпетична невралгія, тригемінальна невралгія, діабетична полінейропатія) 25 Од Ксеоміну",
          price: "2750 грн",
        },
        {
          title: "Лікування нейрогенного болю (постгерпетична невралгія, тригемінальна невралгія, діабетична полінейропатія) 50 Од Ксеоміну",
          price: "4000 грн",
        },
        {
          title:
            "Лікування нейрогенного болю (постгерпетична невралгія, тригемінальна невралгія, діабетична полінейропатія) 75 Од Ксеоміну",
          price: "5250 грн",
        },
        {
          title:
            "Лікування нейрогенного болю (постгерпетична невралгія, тригемінальна невралгія, діабетична полінейропатія) 100 Од Ксеоміну",
          price: "6500 грн",
        },
        {
          title:
            "Лікування міофасціального синдрому (блокада тригерних точок, одна точка) 5 Од Ксеоміну",
          price: "400 грн",
        },
        {
          title:
            "Лікування фантомних болей 150 Од Ксеоміну",
          price: "9000 грн",
        },
        {
          title:
            "Лікування фантомних болей 200 Од Ксеоміну",
          price: "12000 грн",
        },
        {
          title:
            "Лікування фантомних болей 250 Од Ксеоміну",
          price: "14500 грн",
        },
        {
          title:
            "Лікування  болей в куксі 150 Од Ксеоміну",
          price: "9000 грн",
        },
        {
          title:
            "Лікування  болей в куксі 200 Од Ксеоміну",
          price: "12000 грн",
        },
        {
          title:
            "Лікування  болей в куксі 250 Од Ксеоміну",
          price: "14500 грн",
        },
      ],
    },
  },
  {
    path: SERVICE_ENDOCRINOLOGY_ROUTE,
    metaTitle: "Ендокринолог в Чернігові ᐉ консультація, запис - Filada",
    metaDescription:
      "Консультація ендокринолога в Чернігові ᐉ Клініка Filada ⭐ Запис до ендокринолога ✔️ Доступна ціна. Тел.063-503-04-72",
    metaUrl: "https://filada.com.ua/endocrinology/",
    coverDescription: "Ендокринологія",
    abouteTitle: "ЕНДОКРИНОЛОГ В ЧЕРНІГОВІ",
    seoText: textEndocrinology,
    abouteTexts: [
      "Ласкаво просимо на сторінку, присвячену ендокринології в Чернігові! Медичний центр Філада пропонує високоякісні медичні послуги для діагностики та лікування захворювань ендокринної системи.",
      "Наші лікарі спеціалізуються на лікуванні цукрового діабету, захворювань щитоподібної залози, гормональних розладів та інших ендокринних проблем. Ми використовуємо сучасні методи діагностики, включаючи лабораторні дослідження та ультразвукову діагностику.",
      "В медичному центрі Філада ми використовуємо тільки сучасні та перевірені методи лікування.",
    ],
    aboutePhotos: [endoOne, endoTwo, endoThree],
    scrollId: "VTytorenko",
    pricesObj: {
      isComplicated: false,
      services: [
        {
          title: "Консультація ендокринолога",
          price: "600 грн",
        },
        {
          title: "Повторна консультація ендокринолога (протягом 1 місяця)",
          price: "400 грн",
        },
        {
          title: "Консультація ендокринолога + УЗД щитоподібної залози",
          price: "900 грн",
        },
      ],
    },
  },
  {
    path: SERVICE_DIETICS_NUTRITION_ROUTE,
    metaTitle:
      "Дієтолог/нутріціолог в Чернігові ᐉ консультація, запис - Filada",
    metaDescription:
      "Консультація дієтолога/нутріціолога в Чернігові ᐉ Клініка Filada ⭐ Запис до дієтолога/нутріціолога ✔️ Доступна ціна. Тел.063-503-04-72",
    metaUrl: "https://filada.com.ua/dietetics-nutrition/",
    coverDescription: "Дієтологія/Нутріціологія",
    abouteTitle: "Дієтолог/нутріціолог в Чернігові",
    seoText: textDieteticsNutrition,
    abouteTexts: [
      "Ласкаво просимо на нашу сторінку, присвячену дієтології та нутриціології! Важливість здорового харчування важко переоцінити, адже саме від нього залежать наше самопочуття, енергія та загальний стан здоров'я.",
      "Дієтологія – це наука про харчування, яка вивчає, як їжа впливає на організм. Дієтологи допомагають людям розробити індивідуальні плани харчування, що відповідають їхнім потребам, здоров'ю та способу життя.",
      "Нутриціологія фокусується на ролі харчових речовин, їхніх функціях та взаємодії в організмі. Нутриціологи аналізують, як різні складові їжі впливають на здоров'я та розвиток захворювань.",
    ],
    aboutePhotos: [dietOne, dietTwo, dietThree],
    scrollId: "VTytorenko",
    pricesObj: {
      isComplicated: false,
      services: [
        {
          title: "Консультація дієтолога (нутриціолога)",
          price: "600 грн",
        },
        {
          title:
            "Повторна консультація дієтолога (нутриціололга) (протягом 1 місяця)",
          price: "400 грн",
        },
      ],
    },
  },
  {
    path: SERVICE_SURGERY_ROUTE,
    metaTitle: "Хірургія в Чернігові ᐉ консультація, запис - Filada",
    metaDescription:
      "Консультація Хірурга в Чернігові ᐉ Клініка Filada ⭐ Запис до хірурга ✔️ Доступна ціна. Тел.063-503-04-72",
    metaUrl: "https://filada.com.ua/surgery/",
    coverDescription: "Хірургія",
    abouteTitle: "Хірургія в Чернігові",
    seoText: textSurgery,
    abouteTexts: [
      "Хірургія в Чернігові може бути комфортною та доступною.   Хірургічне лікування доброякісних новоутворень шкіри та підшкірної клітковини, лікування кил білої лінії живота, пахових та пупкових кил та інші операції вже доступні в нашому медичному центрі ФІЛАДА",
      "Індивідуальний підхід до кожного пацієнта. Довіра до лікаря – запорука успішного лікування. Саме тому в медичному центрі ФІЛАДА кожен пацієнт отримує персональну консультацію та комплексний супровід на всіх етапах лікування. Наші фахівці приділяють особливу увагу не тільки медичній складовій, а й психологічній підтримці, щоб кожен відчував себе комфортно та впевнено.",
      "Запрошуємо вас відвідати наш медичний центр та переконатися у високій якості наших послуг. Зверніться за консультацією вже сьогодні – довірте своє здоров’я професіоналам, які завжди піклуються про вас!",
    ],
    aboutePhotos: [surgeryOne, proctoTwo, surgeryThree],
    scrollId: "EIvashyn",
    pricesObj: {
      isComplicated: false,
      services: [
        {
          title: "Консультація хірурга",
          price: "600 грн",
        },
        {
          title: "Повторна консультація хірурга (протягом 2 місяців)",
          price: "400 грн",
        },
        {
          title:
            "Видалення дрібних елементів (папілом, точкових гемангіом тощо) – 1 утворення",
          price: "400 грн",
        },
        {
          title:
            "Видалення дрібних елементів (папілом, точкових гемангіом тощо) – 3 утворення",
          price: "800 грн",
        },
        {
          title:
            "Видалення дрібних елементів (папілом, точкових гемангіом тощо) – 5 утворень",
          price: "1100 грн",
        },
        {
          title:
            "Видалення дрібних елементів (папілом, точкових гемангіом тощо) – 10 утворень і більше",
          price: "2200 грн",
        },
        {
          title:
            "Видалення доброякісних новоутворень шкіри та підшкірної клітковини І категорії",
          price: "2000 грн",
        },
        {
          title:
            "Видалення доброякісних новоутворень шкіри та підшкірної клітковини ІІ категорії",
          price: "3000 грн",
        },
        {
          title:
            "Видалення доброякісних новоутворень шкіри та підшкірної клітковини ІІІ категорії",
          price: "4000 грн",
        },
        {
          title: "Зняття швів",
          price: "500 грн",
        },
        {
          title: "Обробка рани (ПХО)",
          price: "600 грн",
        },
        {
          title: "Обробка рани (ПХО) з накладанням швів",
          price: "1000 грн",
        },
        {
          title: "Розтин абсцесу, фурункулу І категорії",
          price: "1500 грн",
        },
        {
          title: "Розтин абсцесу, фурункулу ІІ категорії",
          price: "2500 грн",
        },
        {
          title: "Хірургічне лікування панарицію",
          price: "1500 грн",
        },
        {
          title: "Хірургічне лікування пароніхію",
          price: "1200 грн",
        },
        {
          title:
            "Хірургічне лікування при врослому нігті (крайова резекція з одного боку)",
          price: "1200 грн",
        },
        {
          title:
            "Хірургічне лікування при врослому нігті (крайова резекція з двох боків)",
          price: "1800 грн",
        },
        {
          title: "Хірургічне лікування пупкової кили",
          price: "6500 грн",
        },
        {
          title: "Хірургічне лікування кили білої лінії живота",
          price: "6500 грн",
        },
        {
          title: "Хірургічне лікування пахової кили",
          price: "8500 грн",
        },
        {
          title:
            "Патогістологічне дослідження видаленого новоутворення 1-2 категорії складності (до 3 утворень включно)",
          price: "1200 грн",
        },
        {
          title:
            "Патогістологічне дослідження видаленого новоутворення 3 категорії складності (до 3 утворень включно)",
          price: "1400 грн",
        },
        {
          title:
            "Медикаментозний супровід при оперативному лікуванні (наркоз 1 година)",
          price: "2000 грн",
        },
        {
          title: "Кожна наступна година наркозу ",
          price: "1000 грн",
        },
      ],
    },
  },
  // {
  //   path: SERVICE_VESSELS_ROUTE,
  // metaTitle: "Медичний Центр Філада Чернігів | УЗ діагностика Ціни",
  // metaDescription: "УЗ діагностика Чернігів. Сучасні рішення у УЗ діагностиці. Filada - Сучасний медичний центр у Чернігові",
  // metaUrl: "https://filada.com.ua/proctology",
  //   coverDescription: "УЗ діагностика",
  //   abouteTitle: "УЗ діагностика",
  //   abouteTexts: [
  //     "Персоналізований підхід до кожного пацієнта. Наш медичний центр пропонує індивідуальні програми лікування та обстеження, створені спеціально для ваших потреб. Ми не використовуємо універсальні підходи — кожен пацієнт унікальний і заслуговує на особливу увагу",
  //     "Персоналізований підхід до кожного пацієнта. Наш медичний центр пропонує індивідуальні програми лікування та обстеження, створені спеціально для ваших потреб. Ми не використовуємо універсальні підходи — кожен пацієнт унікальний і заслуговує на особливу увагу",
  //     "Персоналізований підхід до кожного пацієнта. Наш медичний центр пропонує індивідуальні програми лікування та обстеження, створені спеціально для ваших потреб. Ми не використовуємо універсальні підходи — кожен пацієнт унікальний і заслуговує на особливу увагу",
  //   ],
  //   aboutePhotos: [
  //     "https://img.bookimed.com/clinic_webp/654254094587d.webp",
  //     "https://img.bookimed.com/clinic_webp/654254094587d.webp",
  //     "https://img.bookimed.com/clinic_webp/654254094587d.webp",
  //   ],
  //   pricesObj: {
  //     isComplicated: false,
  //     services: [
  //       {
  //         title: "УЗД нирок",
  //         price: "300 грн",
  //       },
  //       {
  //         title: "УЗД сечового міхура",
  //         price: "300 грн",
  //       },
  //       {
  //         title: "УЗД нирок та сечового міхура",
  //         price: "400 грн",
  //       },
  //       {
  //         title: "УЗД передміхурової залози",
  //         price: "300 грн",
  //       },
  //       {
  //         title: "УЗД нирок, сечового міхура та передміхурової залози",
  //         price: "600 грн",
  //       },
  //       {
  //         title: "УЗД щитоподібної залози",
  //         price: "300 грн",
  //       },
  //       {
  //         title: "УЗД органів малого тазу (трансабдомінально)",
  //         price: "400 грн",
  //       },
  //       {
  //         title: "УЗД органів малого тазу (трансвагінально)",
  //         price: "450 грн",
  //       },
  //       {
  //         title: "УЗД серця ",
  //         price: "600 грн",
  //       },
  //       {
  //         title: "УЗД серця черезстравохідне",
  //         price: "1200 грн",
  //       },
  //       {
  //         title: "УЗД серця черезстравохідне з медикаментозним сном",
  //         price: "1700 грн",
  //       },
  //       {
  //         title: "УЗД молочних залоз",
  //         price: "450 грн",
  //       },
  //       {
  //         title: "Дуплексне (триплексне) сканування судин голови та шиї",
  //         price: "600 грн",
  //       },
  //       {
  //         title: "Дуплексне (триплексне) сканування артерій нижніх кінцівок",
  //         price: "500 грн",
  //       },
  //       {
  //         title: "Дуплексне (триплексне) сканування артерій верхніх кінцівок",
  //         price: "500 грн",
  //       },
  //       {
  //         title:
  //           "Дуплексне (триплексне) сканування артерій та вен нижніх кінцівок",
  //         price: "700 грн",
  //       },
  //       {
  //         title:
  //           "Дуплексне (триплексне) сканування артерій та вен верхніх кінцівок",
  //         price: "700 грн",
  //       },
  //       {
  //         title: "Дуплексне (триплексне) сканування вен нижніх кінцівок",
  //         price: "500 грн",
  //       },
  //       {
  //         title: "Дуплексне (триплексне) сканування вен верхніх кінцівок",
  //         price: "500 грн",
  //       },
  //     ],
  //   },
  // },
];
