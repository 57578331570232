import { BEFORE_AFTER_BLEPHAROPLASTY_ROUTE } from "./routes";
import beforeAfterBlepharoplasty1 from '../assets/images/BeforeAfter/blepharoplasty/blepharoplasty1.webp'
import beforeAfterBlepharoplasty2 from '../assets/images/BeforeAfter/blepharoplasty/blepharoplasty2.webp'
import beforeAfterBlepharoplasty5 from '../assets/images/BeforeAfter/blepharoplasty/blepharoplasty5.webp'
import beforeAfterBlepharoplasty6 from '../assets/images/BeforeAfter/blepharoplasty/blepharoplasty6.webp'
import beforeAfterBlepharoplasty7 from '../assets/images/BeforeAfter/blepharoplasty/blepharoplasty7.webp'
import beforeAfterBlepharoplasty8 from '../assets/images/BeforeAfter/blepharoplasty/blepharoplasty8.webp'
import beforeAfterBlepharoplasty9 from '../assets/images/BeforeAfter/blepharoplasty/blepharoplasty9.webp'
import beforeAfterBlepharoplasty10 from '../assets/images/BeforeAfter/blepharoplasty/blepharoplasty10.webp'
import beforeAfterBlepharoplasty11 from '../assets/images/BeforeAfter/blepharoplasty/blepharoplasty11.webp'
import beforeAfterBlepharoplasty12 from '../assets/images/BeforeAfter/blepharoplasty/blepharoplasty12.webp'
import beforeAfterBlepharoplasty13 from '../assets/images/BeforeAfter/blepharoplasty/blepharoplasty13.webp'
import beforeAfterBlepharoplasty14 from '../assets/images/BeforeAfter/blepharoplasty/blepharoplasty14.webp'
import { blepharoplastyBeforeAfterTexts } from "./beforeAfterTexts";

export const BeforeAfterPagesArr = [
    {
         path: BEFORE_AFTER_BLEPHAROPLASTY_ROUTE,
            metaTitle: "До після блефаропластики в Чернігові ᐉ консультація, запис - Filada",
            metaDescription:
              "До після блефаропластики в Чернігові ᐉ Клініка Filada ⭐ Консультація блефаропластика ✔️ Огляд ✔️ Аналізи ✔️ Доступна ціна ✔️ Краще лікування ✔️ PRP – терапія. Тел.063-503-04-72",
            metaUrl: "https://filada.com.ua/before-after/blepharoplasty/",
            coverDescription: "Блефаропластика ДО/ПІСЛЯ",
            beforeAfterTexts: blepharoplastyBeforeAfterTexts,
            beforeAfterPhotosArr: [
                {
                    left: beforeAfterBlepharoplasty7,
                    right: beforeAfterBlepharoplasty8,
                },
                {
                    left: beforeAfterBlepharoplasty5,
                    right: beforeAfterBlepharoplasty6,
                },
                {
                    left: beforeAfterBlepharoplasty1,
                    right: beforeAfterBlepharoplasty2,
                },
                {
                    left: beforeAfterBlepharoplasty11,
                    right: beforeAfterBlepharoplasty12,
                },
                {
                    left: beforeAfterBlepharoplasty13,
                    right: beforeAfterBlepharoplasty14,
                },
                {
                    left: beforeAfterBlepharoplasty9,
                    right: beforeAfterBlepharoplasty10,
                }
            ]           
    }
]