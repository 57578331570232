import photoOne from "../assets/images/Tcikal.webp";
import photoTwo from "../assets/images/Stryshynec-Volodymir.webp";
import photoThree from "../assets/images/SuslovaI.webp";
import photoFour from "../assets/images/ShybaV.webp";
import photoFive from "../assets/images/YvashynE.webp";
import photoSix from "../assets/images/TelnovaU.webp";
import photoSeven from "../assets/images/Kosenko.webp";
import photoEight from "../assets/images/MekshynH.webp";
import photoNine from "../assets/images/KosenkoY.webp";
import photoTen from "../assets/images/DavydenkoO.webp";
import photoEleven from "../assets/images/TytorenkoV.webp";

export const teamArr = [
  {
    name: "Василь Цикал",
    jobTitle: "Гінеколог, лікар УЗД",
    description: (
      <>
        Напрямки діяльності: гінекологія, естетична гінекологія, оперативна гінекологія, УЗ - діагностика в гінекології <br />
        <p className="mont-b">Освіта:</p>{" "}
        <p>
          - Харківський національний медичний університет, «Лікувальна справа»
          (2018)
        </p>{" "}
        <p>
          -Інтернатура за фахом «Акушерство та гінекологія» на базі Харківського
          міського перинатального центру 2018-2021рр
        </p>{" "}
        <p className="mont-b">Курси:</p>{" "}
        <p>-НМАПО ім. П.Л. Шупика , «Лапароскопія в гінекології» (2021)</p>{" "}
        <p>
          -НМАПО ім. П.Л. Шупика , «Кольпоскопія в діагностиці захворювань шийки
          матки» (2022)
        </p>{" "}
        <p>
          -НМАПО ім. П.Л. Шупика , Спеціалізація за фахом «Ультразвукова
          діагностика» (2022)
        </p>{" "}
        <p>-НМАПО ім. П.Л. Шупика , «Гінекологічна ендокринологія» (2024)</p>{" "}
        <p>-Хірургічна естетогінегологія (2024)</p>{" "}
        <p>-Ін’єкційна естетична гінекологія (2024)</p>
      </>
    ),
    src: photoOne,
    isReverce: false,
    scrollId: "VTsikal",
  },
  {
    name: "Ірина Суслова",
    jobTitle: "Дерматовенеролог, косметолог",
    description: (
      <>
        Напрямки діяльності: апаратна косметологія, ін’єкційна косметологія,
        бутулінотерапія, доглядові процедури, контурна пластика, дерматологія (діагностика та лікування захворювань шкіри) <br />
        <p className="mont-b">Освіта:</p>{" "}
        <p>
          - Полтавський державний медичний університет, «Лікувальна справа»
          (2020)
        </p>
        <p>
          -НМАПО ім. П.Л. Шупика, інтернатура за фахом «Загальна практика –
          сімейна медицина» (2022)
        </p>
        <p className="mont-b">Курси:</p>{" "}
        <p>-Школа «Космотрейд», «Класична та апаратна косметологія» (2021)</p>
        <p>
          -Інститут дерматокосметології доктора Богомолець, «Дерматоскопія
          новоутворень шкіри», «Акне. Комплексний підхід до діагностики та
          лікування пацієнтів різного ступеня важкості» (2024)
        </p>
        <p>
          НМАПО ім. П.Л. Шупика, спеціалізація за фахом «Дерматовенерологія»
          (2024)
        </p>
      </>
    ),
    src: photoThree,
    isReverce: true,
    scrollId: "ISuslova",
  },
  {
    name: "Володимир Шуба",
    jobTitle: "Анестезіолог",
    description: (
      <>
        <p className="mont-b f">Освіта:</p>{" "}
        <p>
          - Івано-Франківська державна медична академія, «Лікувальна справа»
          (1997)
        </p>
        <p>
          -НМАПО ім. П.Л. Шупика, інтернатура за фахом «Анестезіологія» (1999)
          Провів більше 10000 наркозів
        </p>
      </>
    ),
    src: photoFour,
    isReverce: false,
    // scrollId: "c",
  },
  {
    name: "Євгеній Івашин",
    jobTitle: "Хірург, онкохірург, проктолог",
    description: (
      <>
        Напрямки діяльності: оперативне та консервативне лікування
        проктологічних захворювань
        <br />
        <p className="mont-b">Освіта:</p>
        <p>
          - Національний медичний університет ім. О.О.Богомольця, «Лікувальна
          справа» (2019)
        </p>
        <p>
          -НМАПО ім. П.Л. Шупика, інтернатура за фахом «Хірургія» на базі
          хірургічного відділення Чернігівської обласної лікарні (2022)
        </p>
        <p className="mont-b">Курси:</p>
        <p>
          -НМАПО ім. П.Л. Шупика, спеціалізація за фахом «Проктологія» (2023)
        </p>
      </>
    ),
    src: photoFive,
    isReverce: true,
    scrollId: "EIvashyn",
  },
  {
    name: "Володимир Стрішенець",
    jobTitle: "Пластичний хірург",
    description: (
      <>
        Напрямки діяльності: пластична хірургія
        <br />
        <p className="mont-b">Освіта:</p>
        <p>
          - Національний медичний університет ім. О.О Богомольця, «Лікувальна
          справа» (2008)
        </p>
        <p>
          -НМАПО ім. П.Л. Шупика, інтернатура за фахом «Хірургія» на базі
          хірургічного відділення Київської обласної клінічної лікарні (2011)
        </p>
        <p className="mont-b">Курси:</p>
        <p>
          -Стажування в клініках США: Mayo Clinic (Arizona), Maricopa Medical
          Center (Arizona), Abrazo Arrowhead Hospital (Arizona), St. Jude
          Medical Center (California) (2016, 2017)
        </p>
        <p>-Вища кваліфікаційна категорія по хірургії (2021)</p>
        <p>
          -Тренінги по роботохірургії на базе навчальних центрів Intuitive
          Surgical (DaVinci Surgical System) у St Jude Hospital, California;
          Abrazo Arrowhead Hospital, Arizona (2017)
        </p>
        <p>
          -Майстер-класи з пластичної хірургії, Ukrainian Academy of Plastic
          Surgery (2019)
        </p>
      </>
    ),
    src: photoTwo,
    isReverce: false,
    scrollId: "d",
  },
  {
    name: "Олександр Косенко",
    jobTitle: "Пластичний хірург",
    description: (
      <>
        Напрямки діяльності: пластична хірургія, дерматохірургія (діагностика та оперативне лікування доброякісних новоутворень шкіри)
        <br />
        <p className="mont-b">Освіта:</p>
        <p>
          -Харківський національний медичний університет, «Лікувальна справа»
          (2018)
        </p>
        <p>
          -НУОЗ України ім. П.Л. Шупика, інтернатура за фахом «Хірургія» (2021)
        </p>
        <p className="mont-b">Курси:</p>
        <p>
          -НУОЗ України ім. П.Л. Шупика, спеціалізація за фахом «Онкохірургія»
          (2021)
        </p>
        <p>
          -Національний медичний університет ім. О.О Богомольця, спеціалізація
          за фахом «Пластична хірургія» (2024)
        </p>
        <p>
          -Стажування в США: The University of Oklahoma, College of Medicine
          (2023)
        </p>
        <p>-Стажування в Чехії: University Hospital Brno (2023)</p>
      </>
    ),
    src: photoSeven,
    isReverce: true,
    scrollId: "OKosenko",
  },
  {
    name: "Уляна Тельнова",
    jobTitle: "Хірург, ін’єкційний косметолог",
    description: (
      <>
        Напрямки діяльності: естетична хірургія
        <br />
        <p className="mont-b">Освіта:</p>
        <p>
          -Тернопільський національний медичний університет ім. І Я.
          Горбачевського, «Лікувальна справа», (2015)
        </p>
        <p>
          -НМАПО ім. П.Л. Шупика, інтернатура за фахом «Хірургія» на базі
          хірургічного відділення Чернігівської обласної лікарні (2018)
        </p>
        <p className="mont-b">Курси:</p>
        <p>-НМАПО ім. П.Л. Шупика, спеціалізація за фахом «Ендоскопія» (ююю)</p>
        <p>-НМАПО ім. П.Л. Шупика, «Основи лапароскопічної хірургії» (2020)</p>
        <p>
          -Міжнародне медичне стажування з питань хірургії. Іспанія, о. Тенерифе
          DKV (2023)
        </p>
        <p>
          -Міжнародний майстер клас: Принципи мультидисциплінарної командної
          роботи в хірургії та реабілітації Іспанія, о. Тенерифе DKV (2023){" "}
        </p>
        <p>
          -Застосування препарату Ботокс у корекції верхньої третини обличчя.
          Allergan (2023)
        </p>
        <p>
          -Інєкційні методики корекції середньої третини обличчя. Allergan
          (2023)
        </p>
        <p>
          -Інєкцйні методики корекції нижньої третини обличчя. Allergan (2023)
        </p>
      </>
    ),
    src: photoSix,
    isReverce: false,
    scrollId: "g",
  },
  {
    name: "Ганна Мекшун",
    jobTitle: "Лікар-отоларинголог",
    description: (
      <>
        Напрямки діяльності: діагностика, консервативне та оперативне лікування
        захворювань вуха, горла та носа, ендоскопія ЛОР-органів
        <br />
        <p className="mont-b">Освіта:</p>
        <p>
          -Українська медична стоматологічна академія, м. Полтава. «Лікувальна
          справа» (2018 рік)
        </p>
        <p>
          -НМАПО ім. П.Л. Шупика, інтернатура за фахом « Отоларингологія» на
          базі отоларингологічного відділення Чернігівської обласної лікарні - (
          2020 рік)
        </p>
      </>
    ),
    src: photoEight,
    isReverce: true,
    scrollId: "HMekshyn",
  },
  {
    name: "Юлія Косенко",
    jobTitle: "Невролог",
    description: (
      <>
        Напрямки діяльності: лікування рухових розладів (хв Паркінсона),
        профілактика інсультів, лікування больових синдромів (в тому числі
        лікування ботулотоксином), лікування інших неврологічних захворювань
        <br />
        <p className="mont-b">Освіта:</p>
        <p>
          Харківський національний медичний університет, «Лікувальна справа»
          (2018):
        </p>
        <p>
          Інтернатура за фахом «Неврологія» на базі НМАПО ім. П.Л. Шупика
          (2018-2020)
        </p>
        <p className="mont-b">Курси:</p>
        <p>
          -9й Міжнародний конгрес европейської інсультної організації, Мюнхен,
          Німеччина (2023)
        </p>
        <p>
          -Стажування в клініці “Helios“ Нінбург, Нижня Саксонія, Німеччина
          (2023)
        </p>
        <p>
          -Школа рухових розладів Міжнародного товариства рухових розладів та
          хвороби Паркінсона, Болонья, Італія (2024)
        </p>
        <p>
          -Tренінг з оволодіння практичних навичок «Больові синдроми та
          ботулотоксин», Farmas нейрошкола ботулінотерапії, Київ (2024)
        </p>
      </>
    ),
    src: photoNine,
    isReverce: false,
    scrollId: "YKosenko",
  },
  {
    name: "Ольга Давиденко",
    jobTitle: "Гінеколог",
    description: (
      <>
        Напрямки діяльності: гінекологія, консультативний прийом, діагностика та
        лікування гінекологічних захворювань
        <br />
        <p className="mont-b">Освіта:</p>
        <p>
          Національний медичний університет ім. О.О.Богомольця, «Лікувальна
          справа» (2011):
        </p>
        <p>
          Інтернатура за фахом «Акушерство та гінекологія» на базі
          Чернігівського пологового будинку 2011-2014рр
        </p>
      </>
    ),
    src: photoTen,
    isReverce: true,
    scrollId: "j",
  },
  {
    name: "Титоренко Валентина",
    jobTitle: "Лікар-ендокринолог, дієтолог та нутриціолог.",
    description: (
      <>
       Напрямки діяльності: ендокринологія, дієтологія, нутриціологія
        <br />
        <p className="mont-b">Освіта:</p>
        <p>
        – Сумський державний університет, спеціальність «Лікувальна справа» (2017)
        </p>
        <p>
        – Національний університет охорони здоров’я України імені П.Л. Шупика,
спеціалізація «Загальна практика – сімейна медицина». (2019)
        </p>
        <p>– Спеціалізація «Ендокринологія», Національний університет охорони здоров’я
України імені П.Л. Шупика (2024)</p>
<p className="mont-b">Курси:</p>
<p>- Курс дієтології, Харківський національний університет ім. В.Н. Каразіна,
медичний факультет.  
(2021)</p>
<p>- Університет біоресурсів і природокористування України, магістерська
програма «Нутриціологія» (2024)</p>

      </>
    ),
    src: photoEleven,
    isReverce: false,
    scrollId: "VTytorenko",
  },
];
