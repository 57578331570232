
const scrollTo = "OKosenko";

export const blepharoplastyBeforeAfterTexts = (
  <dix>
    <h2 className="arial-r-seo">Блефаропластика</h2>
    <p className="seo__description">
      Блефаропластика — це хірургічна процедура, яка допомагає усунути обвислі
      повіки, “мішки” під очима та інші вікові зміни в цій ділянці. Ця операція
      повертає шкірі молодість, а погляду — свіжість і виразність.
    </p>
    <p className="seo__description">Види блефаропластики:</p>
    <p className="seo__list-item">
      Верхня блефаропластика: усуває нависання верхніх повік
    </p>
    <p className="seo__list-item">
      Нижня блефаропластика: коригує “мішки” та зайву шкіру під очима
    </p>
    <p className="seo__list-item">
      Кругова блефаропластика: комплексна процедура, що охоплює і верхні, і
      нижні повіки
    </p>
    <p className="seo__description">
      Тільки лікар на консультації визначає, який вид операції потрібен саме
      вам. Усе залежить від індивідуальних особливостей обличчя та стану шкіри.
      В медичному центрі ФІЛАДА блефаропластику виконує пластичний хірург
      Олександр Косенко.
    </p>
    <p className="seo__description">Консультація лікаря безкоштовна.</p>
  </dix>
);
